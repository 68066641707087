<template>
  <div>
    <div
      class="modal fade"
      id="modalViewPYLT"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalViewPYLTLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content" v-loading="loading">
          <div
            class="sticky top-0 flex justify-content-end bg-white text-base font-semibold z-50 mb-3 mt-2 print:hidden"
          >
            <div v-if="mode === 'update'">
              <button type="button" @click="closeUpdate" class="close bg-white border-0">
                <span class="fs-30 fw-500">&times;</span>
              </button>
            </div>
            <div v-else-if="medicalOrderId">
              <button
                ref="PatientSignButton"
                :disabled="loading"
                class="btn border-none text-black px-3 mx-2 fs-14 fw-500"
                @click="openModalSignPDF(true)"
              >
                <div class="loading">
                  <div v-if="loading" class="spinner-border mr-2" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 16 16">
                  <path
                    fill="#03267A"
                    d="M9.021 1.772a2.445 2.445 0 0 1 3.458 3.457l-.272.271l.086.086a2 2 0 0 1 0 2.828l-1.44 1.44a.5.5 0 0 1-.707-.707l1.44-1.44a1 1 0 0 0 0-1.414l-.086-.086l-5.646 5.647a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.595-.643l1.25-3.75a.5.5 0 0 1 .12-.195l6.625-6.625Zm2.75.707a1.445 1.445 0 0 0-2.042 0L3.187 9.02l-.925 2.774l2.982-.745l6.527-6.527a1.445 1.445 0 0 0 0-2.043ZM4.967 15c-1.235 0-2.208-.566-2.855-1.107l1.284-.32a3.2 3.2 0 0 0 1.57.427c.406 0 .824-.164 1.27-.457c.447-.293.878-.687 1.314-1.095l.113-.107c.385-.361.784-.737 1.166-.996c.408-.278.95-.53 1.517-.311c.376.144.62.41.791.736c.161.306.272.688.38 1.105c.05.19.133.374.23.498a.46.46 0 0 0 .114.112c.025.015.036.015.039.015c.122 0 .331-.082.63-.286c.185-.126.344-.252.502-.379c.098-.078.197-.157.302-.235c.279-.209.52-.349.697-.438a3.05 3.05 0 0 1 .278-.124l.02-.008l.007-.002l.003-.001h.001l.16.473l-.159-.474a.5.5 0 0 1 .321.947l-.004.002a2.141 2.141 0 0 0-.175.08c-.13.064-.321.174-.55.346c-.059.043-.135.104-.223.174c-.181.143-.409.324-.618.466c-.31.212-.741.46-1.192.46c-.436 0-.751-.265-.946-.517a2.435 2.435 0 0 1-.405-.858c-.11-.423-.196-.7-.296-.89c-.09-.168-.17-.232-.266-.27c-.082-.03-.245-.033-.595.206c-.313.212-.655.533-1.058.912l-.1.093c-.431.405-.92.855-1.449 1.202c-.53.348-1.14.621-1.818.621Z"
                  />
                </svg>
                {{ $t("Bệnh nhân Ký") }}
              </button>
              <button
                :disabled="loading"
                class="btn border-none text-black px-3 mx-2 fs-14 fw-500"
                @click="openModalSignPDF(false)"
              >
                <div class="loading">
                  <div v-if="loading" class="spinner-border mr-2" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 16 16">
                  <path
                    fill="#03267A"
                    d="M9.021 1.772a2.445 2.445 0 0 1 3.458 3.457l-.272.271l.086.086a2 2 0 0 1 0 2.828l-1.44 1.44a.5.5 0 0 1-.707-.707l1.44-1.44a1 1 0 0 0 0-1.414l-.086-.086l-5.646 5.647a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.595-.643l1.25-3.75a.5.5 0 0 1 .12-.195l6.625-6.625Zm2.75.707a1.445 1.445 0 0 0-2.042 0L3.187 9.02l-.925 2.774l2.982-.745l6.527-6.527a1.445 1.445 0 0 0 0-2.043ZM4.967 15c-1.235 0-2.208-.566-2.855-1.107l1.284-.32a3.2 3.2 0 0 0 1.57.427c.406 0 .824-.164 1.27-.457c.447-.293.878-.687 1.314-1.095l.113-.107c.385-.361.784-.737 1.166-.996c.408-.278.95-.53 1.517-.311c.376.144.62.41.791.736c.161.306.272.688.38 1.105c.05.19.133.374.23.498a.46.46 0 0 0 .114.112c.025.015.036.015.039.015c.122 0 .331-.082.63-.286c.185-.126.344-.252.502-.379c.098-.078.197-.157.302-.235c.279-.209.52-.349.697-.438a3.05 3.05 0 0 1 .278-.124l.02-.008l.007-.002l.003-.001h.001l.16.473l-.159-.474a.5.5 0 0 1 .321.947l-.004.002a2.141 2.141 0 0 0-.175.08c-.13.064-.321.174-.55.346c-.059.043-.135.104-.223.174c-.181.143-.409.324-.618.466c-.31.212-.741.46-1.192.46c-.436 0-.751-.265-.946-.517a2.435 2.435 0 0 1-.405-.858c-.11-.423-.196-.7-.296-.89c-.09-.168-.17-.232-.266-.27c-.082-.03-.245-.033-.595.206c-.313.212-.655.533-1.058.912l-.1.093c-.431.405-.92.855-1.449 1.202c-.53.348-1.14.621-1.818.621Z"
                  />
                </svg>
                {{ $t("multidisciplinary_board.btn_sign") }}
              </button>
              <button class="btn border-none text-black px-3 mx-2 fs-14 fw-500" @click="onPrintNe">
                <div class="loading">
                  <div v-if="loading" class="spinner-border mr-2" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 14H17C17.2652 14 17.5196 13.8946 17.7071 13.7071C17.8946 13.5196 18 13.2652 18 13V7C18 6.73478 17.8946 6.48043 17.7071 6.29289C17.5196 6.10536 17.2652 6 17 6H3C2.73478 6 2.48043 6.10536 2.29289 6.29289C2.10536 6.48043 2 6.73478 2 7V13C2 13.2652 2.10536 13.5196 2.29289 13.7071C2.48043 13.8946 2.73478 14 3 14H4V9H16V14ZM4 4V0H16V4H17C17.7956 4 18.5587 4.31607 19.1213 4.87868C19.6839 5.44129 20 6.20435 20 7V13C20 13.7956 19.6839 14.5587 19.1213 15.1213C18.5587 15.6839 17.7956 16 17 16H16V20H4V16H3C2.20435 16 1.44129 15.6839 0.87868 15.1213C0.31607 14.5587 0 13.7956 0 13L0 7C0 6.20435 0.31607 5.44129 0.87868 4.87868C1.44129 4.31607 2.20435 4 3 4H4ZM6 18H14V11H6V18ZM6 4H14V2H6V4Z"
                    fill="#03267A"
                  />
                </svg>
                {{ $t("multidisciplinary_board.lbl_print") }}
              </button>
              <button
                class="btn border-none text-black px-3 mx-2 fs-14 fw-500"
                @click="modalUpdate(true)"
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.75 8.99995C15.5511 8.99995 15.3603 9.07897 15.2197 9.21962C15.079 9.36028 15 9.55104 15 9.74995V14.25C15 14.4489 14.921 14.6396 14.7803 14.7803C14.6397 14.9209 14.4489 15 14.25 15H3.75C3.55109 15 3.36032 14.9209 3.21967 14.7803C3.07902 14.6396 3 14.4489 3 14.25V3.74995C3 3.55104 3.07902 3.36028 3.21967 3.21962C3.36032 3.07897 3.55109 2.99995 3.75 2.99995H8.25C8.44891 2.99995 8.63968 2.92094 8.78033 2.78028C8.92098 2.63963 9 2.44887 9 2.24995C9 2.05104 8.92098 1.86028 8.78033 1.71962C8.63968 1.57897 8.44891 1.49995 8.25 1.49995H3.75C3.15326 1.49995 2.58097 1.73701 2.15901 2.15896C1.73705 2.58092 1.5 3.15322 1.5 3.74995V14.25C1.5 14.8467 1.73705 15.419 2.15901 15.8409C2.58097 16.2629 3.15326 16.5 3.75 16.5H14.25C14.8467 16.5 15.419 16.2629 15.841 15.8409C16.2629 15.419 16.5 14.8467 16.5 14.25V9.74995C16.5 9.55104 16.421 9.36028 16.2803 9.21962C16.1397 9.07897 15.9489 8.99995 15.75 8.99995ZM4.5 9.56995V12.75C4.5 12.9489 4.57902 13.1396 4.71967 13.2803C4.86032 13.4209 5.05109 13.5 5.25 13.5H8.43C8.5287 13.5005 8.62655 13.4816 8.71793 13.4443C8.80931 13.407 8.89242 13.352 8.9625 13.2825L14.1525 8.08495L16.2825 5.99995C16.3528 5.93023 16.4086 5.84728 16.4467 5.75589C16.4847 5.66449 16.5043 5.56646 16.5043 5.46745C16.5043 5.36845 16.4847 5.27042 16.4467 5.17902C16.4086 5.08763 16.3528 5.00468 16.2825 4.93495L13.1025 1.71745C13.0328 1.64716 12.9498 1.59136 12.8584 1.55329C12.767 1.51521 12.669 1.49561 12.57 1.49561C12.471 1.49561 12.373 1.51521 12.2816 1.55329C12.1902 1.59136 12.1072 1.64716 12.0375 1.71745L9.9225 3.83995L4.7175 9.03745C4.64799 9.10753 4.593 9.19065 4.55567 9.28202C4.51835 9.3734 4.49943 9.47125 4.5 9.56995V9.56995ZM12.57 3.30745L14.6925 5.42995L13.6275 6.49495L11.505 4.37245L12.57 3.30745ZM6 9.87745L10.4475 5.42995L12.57 7.55245L8.1225 12H6V9.87745Z"
                    fill="#03267A"
                  />
                </svg>
                {{ $t("multidisciplinary_board.lbl_modify_short") }}
              </button>
              <button
                type="button"
                id="closeModal"
                class="close bg-white border-0"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" class="fs-30 fw-500">&times;</span>
              </button>
            </div>
            <div v-else>
              <button
                type="button"
                id="closeModal"
                class="close bg-white border-0"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" class="fs-30 fw-500">&times;</span>
              </button>
            </div>
          </div>
          <div
            v-if="mode === 'update'"
            class="font-bold text-xl text-center uppercase"
          >Phiếu Y lệnh truyền</div>
          <div class="modal-body">
            <div v-if="mode === 'update'">
              <div class="row mt-3">
                <div class="col-md-6">
                  <label class="fw-700">Tên phiếu</label>
                  <input v-model="form.name" class="form--input txt-grey-900 h-10" />
                </div>
                <div class="col-md-6">
                  <label class="fw-700">Bác sĩ điều trị</label>
                  <el-select
                    v-model="form.treatment_doctor_id"
                    filterable
                    allow-create
                    class="w-full"
                    placeholder="Chọn bác sĩ"
                  >
                    <el-option
                      v-for="item in doctors"
                      :key="item.id + 'td'"
                      :label="item.name"
                      :value="item.user_id"
                    />
                  </el-select>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-3">
                  <label class="fw-700">Người pha thuốc</label>
                  <el-select
                    v-model="form.chemical_id"
                    filterable
                    allow-create
                    class="w-full"
                    placeholder="Chọn người pha thuốc"
                  >
                    <el-option
                      v-for="item in doctors"
                      :key="item.id + 'td'"
                      :label="item.name"
                      :value="item.user_id"
                    />
                  </el-select>
                </div>
                <div class="col-md-3">
                  <label class="fw-700">Ngày giờ bàn giao</label>
                  <input
                    type="datetime-local"
                    placeholder="Chọn ngày giờ"
                    class="form--input h-10"
                    v-model="formatterDeliveryTime"
                  />
                </div>
                <div class="col-md-3">
                  <label class="fw-700">Người thực hiện</label>
                  <el-select
                    v-model="form.infusion_id"
                    filterable
                    allow-create
                    class="w-full"
                    placeholder="Chọn người thực hiện"
                  >
                    <el-option
                      v-for="item in doctors"
                      :key="item.id + 'td'"
                      :label="item.name"
                      :value="item.user_id"
                    />
                  </el-select>
                </div>
                <div class="col-md-3">
                  <label class="fw-700">Ngày giờ truyền</label>
                  <input
                    type="datetime-local"
                    placeholder="Chọn ngày giờ"
                    class="form--input h-10"
                    v-model="formatterDate"
                  />
                </div>
              </div>
              <!-- <div class="row mt-3">
                <div class="col-md-2 mb-1">
                  <label class="fw-700">Chiều cao (H)</label>
                  <input v-model.number="form.height" class="form--input txt-grey-900 h-10" />
                </div>
                <div class="col-md-2 mb-1">
                  <label class="fw-700">Cân nặng (W)</label>
                  <input v-model.number="form.weight" class="form--input txt-grey-900 h-10" />
                </div>
                <div class="col-md-2 mb-1">
                  <label class="fw-700">Diện tích Da (BSA)</label>
                  <input v-model.number="form.bsa" class="form--input txt-grey-900 h-10" />
                </div>
                <div class="col-md-2 mb-1">
                  <label class="fw-700">Mức lọc cầu thận (GFR)</label>
                  <input v-model.number="form.gfr" class="form--input txt-grey-900 h-10" />
                </div>
                <div class="col-md-2 mb-1">
                  <label class="fw-700">creatinine (C)</label>
                  <input v-model.number="form.creatinine" class="form--input txt-grey-900 h-10" />
                </div>
                <div class="col-md-2 mb-1">
                  <label class="fw-700">Mạch (P)</label>
                  <input v-model.number="form.pulse" class="form--input txt-grey-900 h-10" />
                </div>
                <div class="col-md-2 mb-1">
                  <label class="fw-700">Nhiệt độ (T)</label>
                  <input v-model.number="form.temperature" class="form--input txt-grey-900 h-10" />
                </div>
                <div class="col-md-2 mb-1 pr-0">
                  <label class="fw-700">Huyết áp (BP)</label>
                  <div class="flex items-center">
                    <input v-model.number="form.bp_systolic" class="form--input txt-grey-900 h-10" />
                    <span class="pl-2">/</span>
                  </div>
                </div>
                <div class="col-md-2 mb-1 flex flex-column">
                  <label class="fw-700">&nbsp;</label>
                  <input v-model.number="form.bp_diastolic" class="form--input txt-grey-900 h-10 mt-auto" />
                </div>
              </div>-->
              <div class="row mt-3">
                <div class="col-md-2 mb-1">
                  <label class="fw-700">Chiều cao (cm)</label>
                  <input v-model.number="form.height" class="form--input txt-grey-900 h-10" />
                </div>
                <div class="col-md-2 mb-1">
                  <label class="fw-700">Cân nặng (kg)</label>
                  <input v-model.number="form.weight" class="form--input txt-grey-900 h-10" />
                </div>
                <div class="col-md-2 mb-1">
                  <label class="fw-700">Creatinine (μmol/L)</label>
                  <input v-model.number="form.creatinine" class="form--input txt-grey-900 h-10" />
                </div>
                <div class="col-md-2 mb-1">
                  <label class="fw-700">Mạch (lần/phút)</label>
                  <input v-model.number="form.pulse" class="form--input txt-grey-900 h-10" />
                </div>
                <div class="col-md-2 mb-1">
                  <label class="fw-700">Nhiệt độ (°C)</label>
                  <input v-model.number="form.temperature" class="form--input txt-grey-900 h-10" />
                </div>
                <div class="col-md-2 mb-1 pr-0">
                  <label class="fw-700">Huyết áp (mmHg)</label>
                  <div class="flex items-center">
                    <input v-model.number="form.bp_systolic" class="form--input txt-grey-900 h-10" />
                    <span class="pl-2">/</span>
                    <input
                      v-model.number="form.bp_diastolic"
                      class="form--input txt-grey-900 h-10 mt-auto"
                    />
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="w-1/2">
                  <label class="fw-700">Diện tích Da (BSA)</label>
                  <div class="form--input txt-grey-900 h-10">{{ bsa }}</div>
                </div>
                <div class="w-1/2">
                  <label class="fw-700">Mức lọc cầu thận (GFR)</label>
                  <div class="form--input txt-grey-900 h-10">{{ gfr }}</div>
                </div>
              </div>
              <div class="row mt-3">
                <div>
                  <div class="fw-700 mb-2">Chẩn đoán</div>
                  <div>
                    <input v-model="form.diagnostic" class="form--input txt-grey-900 h-10" />
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div>
                  <div class="fw-700 mb-2">Y lệnh</div>
                  <TiptapEditor :value="form.content" @setValue="value => (form.content = value)" />
                </div>
              </div>
            </div>

            <div v-if="!mode" ref="documentPrint">
              <div id="PhieuChamSocViewDetail" class="mx-auto pb-9 hodo-print">
                <div>
                  <div class="flex font">
                    <div class="fs-17 uppercase">
                      <div>{{ $globalClinic && $globalClinic.organization ? $globalClinic.organization.authority_in_charge : "" }}</div>
                      <div>{{ $globalClinic && $globalClinic.organization ? $globalClinic.organization.name : "" }}</div>
                      <div>{{ $globalClinic && $globalClinic.name }}</div>
                    </div>

                    <div class="col-3 text-right ml-auto">
                      <div class="fs-17 inline-block text-left">
                        <!-- <div>
                        MS:
                        <span class="font-bold">09/BV-01</span>
                        </div>-->
                        <div>
                          Mã hồ sơ: {{ person.ref_id }}
                          <!-- <span class="font-bold"></span> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="text-center mb-6">
                    <div class="flex flex-col justify-center items-center">
                      <div class="fs-24 font-bold">PHIẾU Y LỆNH TRUYỀN</div>
                      <span class="fs-17">Ngày: {{ appUtils.formatDate(form.date) }}</span>
                    </div>
                  </div>
                  <div class="block mt-4 fs-18">
                    <div>
                      <div class="fs-18">
                        Họ tên BN:
                        <span class="fs-18 font-bold ml-1">{{ person.name }}</span>
                      </div>
                    </div>
                    <div class="block">
                      <div class="col-3 align-top">
                        <div class="fs-18">
                          Tuổi:
                          <span class="fs-18 font-bold ml-1">{{ getAge() }}</span>
                        </div>
                      </div>
                      <div class="col-3 align-top">
                        <div class="fs-18">
                          Giới tính:
                          <span class="fs-18 font-bold ml-1">{{ getGender() }}</span>
                        </div>
                      </div>
                      <div class="col-3 align-top">
                        <div class="fs-18">
                          Buồng:
                          <span class="fs-18 font-bold ml-1">{{ person.admission_room }}</span>
                        </div>
                      </div>
                      <div class="col-3 align-top">
                        <div class="fs-18">
                          <span class="fs-18 font-bold ml-1"></span>
                        </div>
                      </div>
                      <!-- <div class="col-3 align-top">
                      <div class="fs-18">
                        Giường:
                        <span class="fs-18 font-bold ml-1">{{ person.admission_bed }}</span>
                      </div>
                      </div>-->
                    </div>
                    <div class="block">
                      <div class="col-3 align-top">
                        <div class="fs-18">
                          Cao:
                          <span class="fs-18 font-bold ml-1">{{ form.height }} cm</span>
                        </div>
                      </div>
                      <div class="col-3 align-top">
                        <div class="fs-18">
                          Nặng:
                          <span class="fs-18 font-bold ml-1">{{ form.weight }} kg</span>
                        </div>
                      </div>
                      <div class="col-3 align-top">
                        <div class="fs-18">
                          GFR:
                          <span class="fs-18 font-bold ml-1">{{ form.gfr }} mL/phút</span>
                        </div>
                      </div>
                      <div class="col-3 align-top">
                        <div class="fs-18">
                          Cre:
                          <span class="fs-18 font-bold ml-1">{{ form.creatinine }} μmol/L</span>
                        </div>
                      </div>
                    </div>
                    <div class="block">
                      <div class="col-3 align-top">
                        <div class="fs-18">
                          Mạch:
                          <span class="fs-18 font-bold ml-1">{{ form.pulse }} bpm</span>
                        </div>
                      </div>
                      <div class="col-3 align-top">
                        <div class="fs-18">
                          Nhiệt độ:
                          <span class="fs-18 font-bold ml-1">{{ form.temperature }}°C</span>
                        </div>
                      </div>
                      <div class="col-3 align-top">
                        <div class="fs-18">
                          Huyết áp:
                          <span
                            class="fs-18 font-bold ml-1"
                          >{{ form.bp_systolic }}/{{ form.bp_diastolic }} mmHg</span>
                        </div>
                      </div>
                      <div class="col-3 align-top">
                        <div class="fs-18">
                          BSA:
                          <span class="fs-18 font-bold ml-1">{{ bsa }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="fs-18">Chẩn đoán: {{ form.diagnostic }}</div>
                    <div class="fs-18 hodo-print mb-3">
                      <div class="hodo-table border-black fs-18" v-html="formatterContent"></div>
                    </div>
                    <div class="uppercase fs-18">
                      <div class="block">
                        <div class="col-6 align-top">
                          <div class="text-center">BỆNH NHÂN</div>
                          <div class="text-center font-bold mt-16">{{ person.name }}</div>
                        </div>
                        <div class="col-6 align-top">
                          <div class="text-center">BÁC SĨ ĐIỀU TRỊ</div>
                          <div
                            class="text-center font-bold mt-16"
                          >{{ getDoctorName(form.treatment_doctor_id) }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer" v-if="mode === 'update'">
            <button @click="onSubmit" type="button" class="btn bg-pri text-white">Lưu</button>
          </div>
        </div>
      </div>
    </div>
    <ModalSignPDF
      ref="YLenh_ModalSignPDF"
      containerIDProps="YLenh_ContainerSignPDFID"
      elementIDProps="YLenh_SignPDFID"
      imageIDProps="YLenh_SignPDFImageID"
      :envelopeName="envelopeName"
      @onSuccess="handleSignDocumentSuccess"
    />
    <PdfViewer
      ref="YLenh_PdfViewer"
      containerIDProps="ContainerYLenh_PDFViewerID"
      elementIDProps="YLenh_PDFViewerID"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import appUtils from '../../../utils/appUtils'
import { ENVELOPE_DOCUMENT_TYPE, ENVELOPE_NAME_TYPE } from '../../../utils/constants'
import { mixinSignPdf } from '../../../utils/mixinSignPdf'
import TiptapEditor from '../../Editors/Tiptap'
import PdfViewer from '../../PdfViewer/PdfViewer.vue'
import ModalSignPDF from '../../Signature/ModalSignPDF.vue'

export default {
  name: 'PhieuYLenhTruyenDetail',
  components: { TiptapEditor, ModalSignPDF, PdfViewer },
  mixins: [mixinSignPdf],
  props: ['medicalOrderId', 'formData', 'isLoading', 'triggerPatientSign'],
  data () {
    const defaultForm = {
      name: `Phiếu Y lệnh truyền ${appUtils.formatDateTimeFull(Date.now())}`,
      diagnostic: '',
      date: appUtils.formatInputDateTime(Date.now()),
      medical_record_id: null,
      person_id: null,
      weight: null,
      height: null,
      bsa: null,
      gfr: null,
      creatinine: null,
      bp_systolic: null,
      bp_diastolic: null,
      temperature: null,
      pulse: null,
      content: appUtils.tableMedicalOrder(),
      pdf_url: null,
      delivery_time: appUtils.formatInputDateTime(Date.now()),
      org_id: this.$globalClinic?.org_id || null,
      workspace_id: this.$clinicId || null,
      treatment_doctor_id: null,
      chemical_id: null,
      infusion_id: null
    }

    return {
      mode: null,
      defaultForm,
      form: defaultForm,
      loading: this.isLoading || false,
      type: null,
      doctors: [],
      appUtils
    }
  },
  computed: {
    ...mapState({
      person: state => state.person,
      medicalRecordMain: state => state.medicalRecordMain,
      emrData: state => state.emrData
    }),
    bsa () {
      const result = this.formulaCalculateBSA(this.form?.height, this.form?.weight)
      const value = Number(result.toFixed(2))
      const valueArr = value.toString().split('.')
      this.form.bsa = value || 0
      return result ? `${valueArr[1] === '00' ? valueArr[0] : value} m²` : 0
    },
    gfr () {
      const dateString = this.person?.birthday
      const date = new Date(dateString)
      const year = date.getFullYear()
      const age = this.person?.age || new Date().getFullYear() - year
      const genderValue = this.person?.gender === 1 ? 1 : 0.85
      const result = this.form.creatinine ? this.formulaCalculateGFR(age, this.form?.weight, genderValue, this.form.creatinine) : 0
      const value = Number(result.toFixed(2))
      const valueArr = value.toString().split('.')
      this.form.gfr = value || 0
      return result ? `${valueArr[1] === '00' ? valueArr[0] : value} mL/min` : 0
    },
    envelopeName () {
      return this.handleGetEnvelopeName(ENVELOPE_NAME_TYPE.emr, this.emrData)
    },
    formatterDate: {
      get () {
        return appUtils.formatInputDateTime(this.form.date)
      },
      set (newValue) {
        this.form.date = appUtils.formatDateTimeUTCTz(newValue)
        return newValue
      }
    },
    formatterDeliveryTime: {
      get () {
        return appUtils.formatInputDateTime(this.form.delivery_time)
      },
      set (newValue) {
        this.form.delivery_time = appUtils.formatDateTimeUTCTz(newValue)
        return newValue
      }
    },
    formatterContent () {
      const contentArr = (this.form.content.length && this.form.content.split('</tr>')) || ''
      // eslint-disable-next-line no-unused-expressions
      contentArr?.splice(contentArr.length - 1, 0, `<tr> <td colspan="4" rowspan="1" colwidth="228,100,89,91"> <p style="text-align: center;">Người pha hóa chất ký và ghi giờ bàn giao</p><p style="text-align: center;">${this.getDateTime(this.form.delivery_time)}</p><p style="text-align: center;">&nbsp;</p><p style="text-align: center;">&nbsp;</p><p style="text-align: center; font-weight: bold;">${this.getDoctorName(this.form?.chemical_id) || '&nbsp;'}</p></td><td colspan="3" rowspan="1" colwidth="252"> <p style="text-align: center;">Người tiêm truyền ký</p><p style="text-align: center;">&nbsp;</p><p style="text-align: center;">&nbsp;</p><p style="text-align: center;">&nbsp;</p><p style="text-align: center; font-weight: bold;">${this.getDoctorName(this.form?.infusion_id) || '&nbsp;'}</p></td></tr>`)

      const newContent = contentArr.join('')
      return newContent || ''
    }
  },
  async created () {
    await this.getMedicalOrderById()
    await this.getDoctors()
  },
  mounted () {
    let self = this
    self.$nextTick(function () {
      window.$('#modalViewPYLT').on('hidden.bs.modal', function (e) {
        self.mode = null
        // self.form = { ...self.defaultForm }
      })
    })
  },
  watch: {
    isLoading (status) {
      this.loading = status
    },
    medicalOrderId () {
      this.getMedicalOrderById()
    },
    async triggerPatientSign () {
      console.log('run')
      await this.getMedicalOrderById()

      this.openModalSignPDF(true)
    }
  },
  methods: {
    formulaCalculateGFR (age, weight, gender, creatinine) {
      return ((140 - age) * weight * gender) / (creatinine * 0.814)
    },
    formulaCalculateBSA (height, weight) {
      return 0.007184 * Math.pow(height, 0.725) * Math.pow(weight, 0.425)
    },
    async onPrintNe (isOpen) {
      const self = this
      const bodyHTML = self.$refs.documentPrint.innerHTML
      const tempHTML = appUtils.templateHTMLPrint({ bodyHTML })
      const base64Data = appUtils.base64.decode(tempHTML)
      const url = await this.savePdfToS3(base64Data, isOpen)

      return url
    },
    async savePdfToS3 (base64, isOpen = true) {
      try {
        this.loading = true

        const payload = {
          content: base64,
          fileName: 'PhieuYLenhTruyen',
          path: 'medical-order',
          type: 'medical-order',
          type_id: 'medical-order',
          uuid: ''
        }

        const res = await this.$rf.getRequest('DoctorRequest').printDocument(payload)

        if (res?.data) {
          if (isOpen) {
            window.open(res.data?.documentLink, '_blank', 'noreferrer')
          }

          this.loading = false
          await this.$rf.getRequest('DoctorRequest').postUpdateMedicalOrder(this.medicalOrderId, {
            pdf_url: res.data?.documentLink
          })

          return res.data?.documentLink
        }
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    async getMedicalOrderById () {
      let id = this.medicalOrderId
      if (!id) return

      this.loading = true
      await this.$rf
        .getRequest('DoctorRequest')
        .getMedicalOrderById(id)
        .then(r => {
          this.form = {
            ...r?.data?.data,
            treatment_doctor_id: r?.data?.data?.treatment_doctor_id !== 0 ? r?.data?.data?.treatment_doctor_id : null,
            chemical_id: r?.data?.data?.chemical_id !== 0 ? r?.data?.data?.chemical_id : null,
            infusion_id: r?.data?.data?.infusion_id !== 0 ? r?.data?.data?.infusion_id : null
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    closeUpdate () {
      this.mode = null
    },
    modalUpdate () {
      this.getMedicalOrderById()
      this.mode = 'update'
    },
    getGender () {
      return appUtils.getGender(this.person.gender)
    },
    formatDay (date) {
      if (!date) return ''
      return window.moment(date).format('DD/MM/YYYY')
    },
    formatHours (date) {
      if (!date) return ''
      return window.moment(date).format('HH:mm')
    },
    getAge () {
      return this.person.birthday && (window.moment().diff(this.person.birthday, 'years', false) ? window.moment().diff(this.person.birthday, 'years', false) : '')
    },
    async getDoctors () {
      const clinic_id = this.$clinicId
      await this.$rf
        .getRequest('DoctorRequest')
        .getDoctorsOfWorkspace(clinic_id, { limit: 1000 })
        .then(res => {
          this.doctors = res.data?.data
        })
    },
    async onSubmit () {
      if (!this.medicalOrderId) return

      try {
        this.loading = true
        const params = {
          ...this.form,
          date: appUtils.formatDateTimeUTCTz(this.form.date),
          delivery_time: appUtils.formatDateTimeUTCTz(this.form.delivery_time)
        }
        console.log(params)
        await this.$rf
          .getRequest('DoctorRequest')
          .postUpdateMedicalOrder(this.medicalOrderId, params)
          .then(async res => {
            this.closeUpdate()
            this.$emit('refresh')
            await this.getMedicalOrderById()
            this.loading = false
            this.$message({
              message: 'Cập nhật thành công',
              type: 'success'
            })
          })
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    getDoctorName (user_id) {
      if (!user_id || !this.doctors?.length) return ''
      const doctor = this.doctors?.find(d => d.user_id === user_id)
      if (!doctor || typeof doctor === 'undefined') return ''
      return doctor.name
    },
    getDoctorInfo (user_id) {
      if (!user_id || !this.doctors?.length) return
      const doctor = this.doctors?.find(d => d.user_id === user_id)
      return doctor
    },
    getDateTime (date) {
      let string = this.moment(date)
      string = string.format('HH:mm-DD/MM/YYYY')
      string = string.replace(':', ' giờ ')
      string = string.replace('-', ' phút, ngày ')
      string = string.replace('00:00', '--:--')
      return string
    },
    async openModalSignPDF (isPatientSign) {
      let error
      let signer

      const documentLink = await this.onPrintNe(false)

      if (!documentLink) {
        this.$toast.open({
          message: 'Không tìm thấy tài liệu cần ký',
          type: 'error'
        })
        return
      }

      const documentType = ENVELOPE_DOCUMENT_TYPE.MEDICAL_ORDER_FORM

      const viewRef = 'YLenh_PdfViewer'
      const signRef = 'YLenh_ModalSignPDF'

      const envelopeInfo = await this.handleCheckSignEnvelope(documentType, this.form?.id, viewRef, signRef, isPatientSign)

      if (envelopeInfo) return

      const procedure = await this.handleProcessProcedureData(
        (err, signerData) => {
          if (err) {
            error = true

            this.$toast.open({
              message: err,
              type: 'error'
            })
          } else {
            signer = signerData
          }
        },
        documentType,
        isPatientSign
      )

      if (error) return

      const hasPermissionSignatory = procedure?.signatories?.some(item => item?.user_id === this.$user?.id)

      if (!hasPermissionSignatory && !isPatientSign) {
        this.$toast.open({
          message: 'Bạn không có quyền ký tài liệu này',
          type: 'error'
        })
        return
      }

      this.$nextTick(() => {
        this.$emit('onSignPatientLoading', false)
        this.$refs[signRef].handleOpen(documentLink, documentType, Number(this.form.id), signer?.id, procedure?.signatories || [], null, procedure?.id, isPatientSign)
      })
    },
    getUserByRole (role, callback, isPatientSign) {
      let user
      let signer
      switch (role) {
        case 'chemical_mixer':
          const chemicalMixer = this.getDoctorInfo(this.form?.chemical_id)?.user
          if (!chemicalMixer) {
            // eslint-disable-next-line standard/no-callback-literal
            callback('Không tìm thấy thông tin Người pha hoá chất')
          } else {
            user = chemicalMixer
            if (user?.id === this.$user?.id && user?.id && !isPatientSign) {
              signer = user
              // eslint-disable-next-line standard/no-callback-literal
              callback(null, signer)
            }
          }
          break

        case 'infusion_nurse':
          const infusionNurse = this.getDoctorInfo(this.form?.infusion_id)?.user

          if (infusionNurse) {
            user = infusionNurse
            if (user?.id === this.$user?.id && user?.id && !isPatientSign) {
              signer = user
              // eslint-disable-next-line standard/no-callback-literal
              callback(null, signer)
            }
          } else {
            // eslint-disable-next-line standard/no-callback-literal
            callback('Không tìm thấy thông tin Người tiêm truyền')
          }

          break

        case 'treatment_doctor':
          const treatmentDoctor = this.getDoctorInfo(this.form?.treatment_doctor_id)?.user

          if (treatmentDoctor) {
            user = treatmentDoctor
            if (user?.id === this.$user?.id && user?.id && !isPatientSign) {
              signer = user
              // eslint-disable-next-line standard/no-callback-literal
              callback(null, signer)
            }
          } else {
            // eslint-disable-next-line standard/no-callback-literal
            callback('Không tìm thấy thông tin Bác sĩ điều trị')
          }

          break

        case 'patient':
          const patient = {
            id: this.person?.user?.id,
            name: this.person?.name,
            email: this.person?.user?.email,
            person_id: this.person.id
          }

          user = patient
          if (isPatientSign) {
            signer = user
            // eslint-disable-next-line standard/no-callback-literal
            callback(null, signer)
          }

          break
        default:
          break
      }

      return user
    },

    async handleSignDocumentSuccess () {
      window.$('#modalViewPYLT').modal('hide')

      // this.backList()
      // try {
      //   if (!envelopeData?.id) return
      //   const request = new SignatureRequest()
      //   const response = await request.getSignEnvelopeByID(envelopeData?.id)
      //   const envelopeStatusCompleted =
      //     response?.data?.status === SIGN_ENVELOPE_STATUS.completed
      //   if (envelopeStatusCompleted) {
      //     //
      //   }
      // } catch (error) {
      //   console.log(error)
      // }
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .el-table--border,
  .el-table--group {
    border: 1px solid #000;
  }

  .el-table--border th.el-table__cell,
  .el-table__fixed-right-patch {
    border-bottom: 1px solid #000 !important;
  }

  .el-table__header {
    width: 100%;
  }

  .el-table--border .el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-right: 1px solid #000 !important;
    border-bottom: 1px solid #000 !important;
  }

  .el-table--border th.el-table__cell > div.cell {
    line-height: 1.25 !important;
  }

  // div {
  //   word-break: inherit !important;
  //   white-space: pre;
  // }
  div.cell {
    padding: 0 4px !important;

    a {
      color: #20409b !important;
    }
  }

  thead th.padding-header {
    padding: 0 8px !important;
  }

  thead th.header-text-left {
    text-align: left !important;
    padding: 0 8px !important;
  }

  .break-words {
    overflow-wrap: break-word !important;
    word-break: break-word !important;
    word-wrap: break-word !important;
    white-space: break-spaces !important;
  }

  body,
  html {
    font-family: "Times New Roman", Tinos, Times, serif !important;
  }

  .font {
    font-family: "Times New Roman", Tinos, Times, serif !important;
  }

  .checkbox {
    display: inline-flex;
    cursor: pointer;
    position: relative;
  }

  p {
    margin-top: 0;
  }

  .text-italic {
    font-style: italic;
  }

  .relative {
    position: relative;
  }

  .absolute {
    position: absolute;
  }

  .top-0 {
    top: 0;
  }

  .right-0 {
    right: 0;
  }

  .left-0 {
    left: 0;
  }

  .px-8 {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .pt-12 {
    padding-top: 3rem;
  }

  .p-0 {
    padding: 0;
  }

  .p-1 {
    padding: 0.25rem;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .float-left {
    float: left;
  }

  .float-right {
    float: right;
  }

  .float-none {
    float: none;
  }

  .block {
    display: block;
  }

  .inline-block {
    display: inline-block !important;
  }

  .flex {
    display: flex;
  }

  .flex-col {
    flex-direction: column;
  }

  .align-top {
    vertical-align: top;
  }

  .text-center {
    text-align: center;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .leading-none {
    line-height: 1;
  }

  .align-middle {
    vertical-align: middle;
  }

  .justify-center {
    justify-content: center;
  }

  .justify-end {
    justify-content: end;
  }

  .justify-between {
    justify-content: space-between;
  }

  .items-start {
    align-items: flex-start;
  }

  .items-center {
    align-items: center;
  }

  .col-4 {
    width: 33.33% !important;
    display: inline-block !important;
    vertical-align: top;
  }

  .col-3 {
    width: 25% !important;
    display: inline-block !important;
    vertical-align: top;
  }

  .col-5 {
    width: 41% !important;
    display: inline-block !important;
    vertical-align: top;
  }

  .col-6 {
    width: 50% !important;
    display: inline-block !important;
    vertical-align: top;
  }

  .col-7 {
    width: 50% !important;
    display: inline-block !important;
    vertical-align: top;
  }

  .col-12 {
    width: 100% !important;
    display: inline-block !important;
    vertical-align: top;
  }

  .w-full {
    width: 100% !important;
  }

  .w-half {
    width: 50% !important;
  }

  .w-one-third {
    width: 33.333% !important;
  }

  .w-two-thirds {
    width: 66.667% !important;
  }

  .font-bold {
    font-weight: 700;
  }

  .text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .text-sm {
    font-size: 0.92rem;
    line-height: 1.25rem;
  }

  .text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .text-base {
    font-size: 1rem;
    /* 16px */
    line-height: 1.5rem;
    /* 24px */
  }

  .pl-3 {
    padding-left: 0.75rem;
  }

  .pl-12 {
    padding-left: 3rem;
  }

  .pl-16 {
    padding-left: 4rem;
  }

  .pr-12 {
    padding-right: 3rem;
  }

  .pr-16 {
    padding-right: 4rem;
  }

  .m-6 {
    margin: 1.5rem;
  }

  .mt-2 {
    margin-top: 0.5rem;
  }

  .mt-3 {
    margin-top: 0.75rem;
  }

  .mt-4 {
    margin-top: 1rem;
  }

  .ml-6 {
    margin-left: 1.5rem;
  }

  .mt-6 {
    margin-top: 1.5rem;
  }

  .mt-8 {
    margin-top: 2rem;
  }

  .mt-10 {
    margin-top: 2.5rem;
  }

  .mt-12 {
    margin-top: 3rem;
  }

  .mt-16 {
    margin-top: 4rem;
  }

  .mt-24 {
    margin-top: 6rem;
  }

  .mt-44 {
    margin-top: 11rem;
  }

  .ml-1 {
    margin-left: 0.25rem;
  }

  .ml-2 {
    margin-left: 0.5rem;
  }

  .ml-3 {
    margin-left: 0.75rem;
  }

  .ml-4 {
    margin-left: 1rem;
  }

  .mr-1 {
    margin-right: 0.25rem;
  }

  .mb-0 {
    margin-bottom: 0;
  }

  .mb-1 {
    margin-bottom: 0.25rem;
  }

  .mb-2 {
    margin-bottom: 0.5rem;
  }

  .mb-3 {
    margin-bottom: 0.75rem;
  }

  .mb-6 {
    margin-bottom: 1.5rem;
  }

  .mb-8 {
    margin-bottom: 2rem;
  }

  .table-width-full table {
    width: 100% !important;
    // border-collapse: collapse !important;
  }

  .hodo-table figure {
    margin: 0 0 1rem !important;
  }

  .hodo-table table {
    border-collapse: collapse !important;
    width: 100% !important;
    border: 1px solid #dbdde0 !important;
    margin-top: 1rem !important;
  }

  .hodo-table table thead th {
    text-transform: uppercase !important;
  }

  .hodo-table table th,
  .hodo-table table td {
    padding: 8px !important;
    border: 1px solid #dbdde0 !important;
  }

  .hodo-table table td p {
    min-height: 25px !important;
  }

  .box-number {
    border: 1px solid;
    height: 1.5rem !important;
  }

  .box-item {
    font-family: "Times New Roman", Tinos, Times, serif !important;
    height: 1.5rem !important;
    width: 1.5rem !important;
    border-right: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .box-item-bhyt {
    font-family: "Times New Roman", Tinos, Times, serif !important;
    height: 0.5rem !important;
    padding: 0.5rem;
    border-right: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .box-item-bhyt:last-child,
  .box-item:last-child {
    border-right: none;
  }

  .checkbox {
    display: inline-flex;
    cursor: pointer;
    position: relative;
  }
}
</style>

