<template>
  <div class="modal fade" id="modalPhieuSoKet" tabindex="-1" role="dialog" aria-labelledby="modalPhieuSoKetLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content" v-loading="loading">
        <div class="d-flex justify-content-between p-3">
          <div class="fs-24 fw-700 txt-grey-900 text-center w-100">
            <span>PHIẾU SƠ KẾT ĐIỀU TRỊ</span>
          </div>
          <button type="button" id="closeModal" class="close bg-white border-0" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" class="fs-30 fw-500">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row mt-1">
            <div class="col-md-6">
              <label class="fw-700">Tên Phiếu</label>
              <input v-model="form.name" class="form--input txt-grey-900 h-10" />
            </div>

            <div class="col-md-3">
              <label class="fw-700">Ngày giờ điều trị</label>
              <el-date-picker type="datetime" placeholder="Chọn ngày giờ" v-model="form.date"></el-date-picker>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-md-12">
              <label class="fw-700">Giải phẫu bệnh</label>
              <input v-model="form.pathology" class="form--input txt-grey-900 h-10" />
            </div>
          </div>
          <div class="mt-3 mb-3">
            <div>
              <label for="txt-name" class="fs-14 fw-700 fw-bold mb-1">Đã điều trị</label>
              <CkeditorNormal id="treated-psk" @onEditorInput="(t) => { form.treated = t }" :prop_text="form.treated"
                :placeholder="''">
              </CkeditorNormal>
            </div>
          </div>

          <span class="fs-14 fw-700 text-black">Kết quả điều trị</span>

          <div class="m-0 mb-1">
            <div>
              <label for="txt-name" class="fs-14 fw-400 mb-2">Dung nạp thuốc</label>
              <CkeditorNormal id="tolerance-psk" @onEditorInput="(t) => { form.tolerance = t }"
                :prop_text="form.tolerance" :placeholder="''"></CkeditorNormal>
            </div>
          </div>
          <div class="m-0 mb-2">
            <div>
              <label for="txt-name" class="fs-14 fw-400 mb-1">Đáp ứng</label>
              <CkeditorNormal id="drug_response-psk" @onEditorInput="(t) => { form.drug_response = t }"
                :prop_text="form.drug_response" :placeholder="''">
              </CkeditorNormal>
              <!-- <CkeditorNormal @onEditorInput="
                (t) => {
                  form.drug_response = t;
                }
              " :prop_text="form.drug_response" :placeholder="''" :class_style="'bg-white p-0 txt-black'"
                :id="'inputTextConclusion'"></CkeditorNormal> -->
            </div>
          </div>
          <div class="m-0 mb-2">
            <div>
              <label for="txt-name" class="fs-14 fw-400 mb-1">Lâm sàng</label>
              <CkeditorNormal id="clinical-psk" @onEditorInput="(t) => { form.clinical = t }" :prop_text="form.clinical"
                :placeholder="''"></CkeditorNormal>
              <!-- <CkeditorNormal @onEditorInput="
                (t) => {
                  form.clinical = t;
                }
              " :prop_text="form.clinical" :placeholder="''" :class_style="'bg-white p-0 txt-black'"
                :id="'inputTextConclusion'"></CkeditorNormal> -->
            </div>
          </div>
          <div class="m-0 mb-2">
            <div>
              <label for="txt-name" class="fs-14 fw-400 mb-1">Cận lâm sàng</label>
              <CkeditorNormal id="subclinical-psk" @onEditorInput="(t) => { form.subclinical = t }"
                :prop_text="form.subclinical" :placeholder="''"></CkeditorNormal>
              <!-- <CkeditorNormal @onEditorInput="
                (t) => {
                  form.subclinical = t;
                }
              " :prop_text="form.subclinical" :placeholder="''" :class_style="'bg-white p-0 txt-black'"
                :id="'inputTextConclusion'"></CkeditorNormal> -->
            </div>
          </div>

          <div class="m-0 mb-2">
            <div>
              <label for="txt-name" class="fs-14 fw-700 fw-bold mb-1">Kết luận</label>
              <!-- <CkeditorNormal @onEditorInput="
                (t) => {
                  form.conclusion = t;
                }
              " :prop_text="form.conclusion" :placeholder="''" :class_style="'bg-white p-0 txt-black'"
                :id="'inputTextConclusion'"></CkeditorNormal> -->
              <CkeditorNormal id="conclusion-psk" @onEditorInput="(t) => { form.conclusion = t }"
                :prop_text="form.conclusion" :placeholder="''"></CkeditorNormal>
            </div>
          </div>
          <div class="m-0 mb-2">
            <div>
              <label for="txt-name" class="fs-14 fw-700 fw-bold mb-1">Hướng điều trị</label>
              <CkeditorNormal id="treatment-psk" @onEditorInput="(t) => { form.treatment = t }"
                :prop_text="form.treatment" :placeholder="''"></CkeditorNormal>
              <!-- <CkeditorNormal @onEditorInput="
                (t) => {
                  form.treatment = t;
                }
              " :prop_text="form.treatment" :placeholder="''" :class_style="'bg-white p-0 txt-black'"
                :id="'inputTextConclusion'"></CkeditorNormal> -->
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="onSubmit" type="button" class="btn bg-pri text-white">Lưu phiếu</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CkeditorNormal from '@/components/Editors/CkeditorNormal.vue'
export default {
  name: 'ModalPSK',
  components: { CkeditorNormal },
  props: ['type'],
  data () {
    return {
      loading: false,
      form: {
        name: 'Phiếu sơ kết ' + window.moment().format('DD/MM/YYYY'),
        date: window.moment().format(`YYYY-MM-DDTHH:mm`),
        format: 1,
        treated: '',
        pathology: '',
        treatment: '',
        tolerance: '',
        drug_response: '',
        clinical: '',
        subclinical: '',
        conclusion: '',
        disease_progression: '',
        execute_assess: ''
      }
    }
  },
  mounted () {
    this.getERMTreatSumByID()
    if (this.type === 'update') {
      this.getERMTreatSumByID()
    }
  },
  methods: {
    async getERMTreatSumByID () {
      let id = this.$route.query.vId
      if (!id) {
        return
      }
      this.loading = true
      await this.$rf
        .getRequest('DoctorRequest')
        .getERMTreatSumByID(id)
        .then(r => {
          this.form = r.data
          this.form = {
            name: r.data.name,
            date: r.data.date,
            treated: r.data.treated || '',
            pathology: r.data.pathology || '',
            treatment: r.data.treatment || '',
            tolerance: r.data.tolerance || '',
            drug_response: r.data.drug_response || '',
            clinical: r.data.clinical || '',
            subclinical: r.data.subclinical || '',
            conclusion: r.data.conclusion || '',
            disease_progression: r.data.disease_progression || '',
            execute_assess: r.data.execute_assess || ''
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    onSubmit () {
      if (this.type === 'create') {
        this.form.medical_record_id = this.$route.params.id
        this.$emit('onCreate', this.form)
        this.form = {
          name: 'Phiếu sơ kết ' + window.moment().format('DD/MM/YYYY'),
          date: null,
          treatment: '',
          disease_progression: '',
          execute_assess: ''
        }
        window.$('button#closeModal').trigger('click')
      } else {
        this.form.medical_record_id = this.$route.params.id
        this.$emit('onUpdate', this.form)
        this.getERMTreatSumByID()
        window.$('button#closeModal').trigger('click')
      }
    },
    async postERMTreatSum () {
      this.loading = true

      const params = {
        ...this.form,
        medical_record_id: this.$route.params.id
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .postERMTreatSum(params)
        .then(r => {
          this.$toast.open({
            message: 'Tải lên thành công',
            type: 'success'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    async updateERMTreatSum (id) {
      this.loading = true
      const params = {
        ...this.form,
        medical_record_id: this.$route.params.id
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .updateERMTreatSum(id, params)
        .then(r => {
          this.$toast.open({
            message: 'Chỉnh sửa thành công',
            type: 'success'
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
