<template>
  <div class="modal fade" id="ModalPYLT" tabindex="-1" role="dialog" aria-labelledby="ModalPYLTLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content" v-loading="loading">
        <div class="d-flex justify-content-between p-3">
          <div class="fs-24 fw-700 txt-grey-900 text-center w-100">Phiếu Y lệnh truyền</div>
          <button type="button" @click="closeModal" class="close bg-white border-0">
            <span class="fs-30 fw-500">&times;</span>
          </button>
        </div>
        <div class="modal-body py-10">
          <div class="row mt-3">
            <div class="col-md-6">
              <label class="fw-700">Mẫu phiếu dùng cho Bệnh nhân</label>
              <el-select placeholder="Chọn mẫu phiếu" class="w-full" v-model="selected_template"
                         @change="changeSelectTemplate">
                <el-option v-for="item in list_template" :key="item.id" :label="item.name" :value="item.id">
                  <div class="flex justify-between">
                    <div>{{ item.name }}</div>
                    <el-button @click.prevent.stop="deleteTemplate(item)" type="info" size="small" icon="el-icon-delete"
                               class="m-1"></el-button>
                  </div>
                </el-option>
              </el-select>
            </div>
            <div class="col-md-6">
              <label class="fw-700">Mẫu phiếu dùng chung</label>
              <el-select clearable filterable v-model="doc_template_id" class="w-full" placeholder="Chọn mẫu phiếu"
                         @change="handleChangeDocTemplate" remote :remote-method="getDocsTemplateList"
                         :loading="loading">
                <el-option v-for="item in docsTemplateList" :key="item.id + 'md'" :label="handleGetTemLabel(item)"
                           :value="item.id"/>
              </el-select>
              <!-- <el-select placeholder="Chọn mẫu phiếu" class="w-full" v-model="selected_template" @change="changeSelectTemplate">
                <el-option v-for="item in list_template" :key="item.id" :label="item.name" :value="item.id">
                  <div class="flex justify-between">
                    <div>{{ item.name }}</div>
                    <el-button @click.prevent.stop="deleteTemplate(item)" type="info" size="small" icon="el-icon-delete" class="m-1"></el-button>
                  </div>
                </el-option>
              </el-select> -->
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-6">
              <label class="fw-700">Tên phiếu</label>
              <input v-model="formCreate.name" class="form--input txt-grey-900 h-10"/>
            </div>
            <div class="col-md-6">
              <label class="fw-700">Bác sĩ điều trị</label>
              <el-select v-model="formCreate.treatment_doctor_id" filterable allow-create class="w-full"
                         placeholder="Chọn bác sĩ">
                <el-option v-for="item in doctors" :key="item.id + 'td'" :label="item.name" :value="item.user_id"/>
              </el-select>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-3">
              <label class="fw-700">Người pha thuốc</label>
              <el-select v-model="formCreate.chemical_id" filterable allow-create class="w-full"
                         placeholder="Chọn người pha thuốc">
                <el-option v-for="item in doctors" :key="item.id + 'td'" :label="item.name" :value="item.user_id"/>
              </el-select>
            </div>
            <div class="col-md-3">
              <label class="fw-700">Ngày giờ bàn giao</label>
              <input type="datetime-local" placeholder="Chọn ngày giờ" class="form--input h-10"
                     v-model="formCreate.delivery_time"/>
            </div>
            <div class="col-md-3">
              <label class="fw-700">Người thực hiện</label>
              <el-select v-model="formCreate.infusion_id" filterable allow-create class="w-full"
                         placeholder="Chọn người thực hiện">
                <el-option v-for="item in doctors" :key="item.id + 'td'" :label="item.name" :value="item.user_id"/>
              </el-select>
            </div>
            <div class="col-md-3">
              <label class="fw-700">Ngày giờ truyền</label>
              <input type="datetime-local" placeholder="Chọn ngày giờ" class="form--input h-10"
                     v-model="formCreate.date"/>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-2 mb-1">
              <label class="fw-700">Chiều cao (cm)</label>
              <input v-model.number="formCreate.height" class="form--input txt-grey-900 h-10"/>
            </div>
            <div class="col-md-2 mb-1">
              <label class="fw-700">Cân nặng (kg)</label>
              <input v-model.number="formCreate.weight" class="form--input txt-grey-900 h-10"/>
            </div>
            <div class="col-md-2 mb-1">
              <label class="fw-700">Creatinine (μmol/L)</label>
              <input v-model.number="formCreate.creatinine" class="form--input txt-grey-900 h-10"/>
            </div>
            <div class="col-md-2 mb-1">
              <label class="fw-700">Mạch (lần/phút)</label>
              <input v-model.number="formCreate.pulse" class="form--input txt-grey-900 h-10"/>
            </div>
            <div class="col-md-2 mb-1">
              <label class="fw-700">Nhiệt độ (°C)</label>
              <input v-model.number="formCreate.temperature" class="form--input txt-grey-900 h-10"/>
            </div>
            <div class="col-md-2 mb-1 pr-0">
              <label class="fw-700">Huyết áp (mmHg)</label>
              <div class="flex items-center">
                <input v-model.number="formCreate.bp_systolic" class="form--input txt-grey-900 h-10"/>
                <span class="pl-2">/</span>
                <input v-model.number="formCreate.bp_diastolic" class="form--input txt-grey-900 h-10 mt-auto"/>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="w-1/2">
              <label class="fw-700">Diện tích Da (BSA)</label>
              <div class="form--input txt-grey-900 h-10">{{ bsa }}</div>
            </div>
            <div class="w-1/2">
              <label class="fw-700">Mức lọc cầu thận (GFR)</label>
              <div class="form--input txt-grey-900 h-10">{{ gfr }}</div>
            </div>
          </div>
          <div>
            <div class="row mt-3">
              <div class="col-lg-4">
                <label class="fw-700">Liều Carboplatin (AUC)</label>
                <div class="flex items-center">
                  <input v-model.number="aucForm" placeholder="Liều Carboplatin (AUC)" class="w-24 form--input txt-grey-900 h-10"/>
                  <span class="mx-2">=</span>
                  <div class="relative form--input txt-grey-900 h-10">
                    <span>{{ aucValue }}</span>
                    <div @click="copyValue(aucRaw)" class="text-center w-10 h-10 border absolute top-0 right-0 cursor-pointer hover:bg-blue-200 hover:text-blue-900 transition-all duration-200">
                      <i class="el-icon-copy-document" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <label class="fw-700">Liều thuốc (BSA)</label>
                <div class="flex items-center">
                  <input v-model.number="bsaForm" placeholder="Liều thuốc (BSA)" class="w-24 form--input txt-grey-900 h-10"/>
                  <span class="mx-2">=</span>
                  <div class="relative form--input txt-grey-900 h-10">
                    <span>{{ bsaValue }}</span>
                    <div @click="copyValue(bsaRaw)" class="text-center w-10 h-10 border absolute top-0 right-0 cursor-pointer hover:bg-blue-200 hover:text-blue-900 transition-all duration-200">
                      <i class="el-icon-copy-document" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <label class="fw-700">Liều thuốc (Weight)</label>
                <div class="flex items-center">
                  <input v-model.number="weightForm" placeholder="Liều thuốc (Weight)" class="w-24 form--input txt-grey-900 h-10"/>
                  <span class="mx-2">=</span>
                  <div class="relative form--input txt-grey-900 h-10">
                    <span>{{ weightValue }}</span>
                    <div @click="copyValue(weightRaw)" class="text-center w-10 h-10 border absolute top-0 right-0 cursor-pointer hover:bg-blue-200 hover:text-blue-900 transition-all duration-200">
                      <i class="el-icon-copy-document" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-xs mt-1">Lưu ý kết quả từ công thức: Liều Carboplatin (AUC), Liều thuốc (BSA), Liều thuốc (Weight) sẽ không được lưu lại, Bác sĩ vui lòng sao chép kết quả để sử dụng.</div>
          </div>

          <div class="row mt-3">
            <div>
              <div class="fw-700 mb-2">Chẩn đoán</div>
              <div>
                <input v-model="formCreate.diagnostic" class="form--input txt-grey-900 h-10"/>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div>
              <div class="fw-700 mb-2">Y lệnh</div>
              <TiptapEditor :value="formCreate.content" @setValue="value => (formCreate.content = value)"/>
            </div>
          </div>
        </div>
        <div class="modal-footer border-t">
          <div class="mr-auto">
            <span @click="handleUseVitalsign" class="txt-pri underline cursor-pointer">Sử dụng dữ liệu mới nhất của chỉ số sinh tồn</span>
          </div>
          <div class="pl-4">
            <button @click="onSubmitTemplate" type="button" class="btn bg-white">Lưu mẫu dùng riêng cho Bệnh nhân
            </button>
            <button @click="onSubmit" type="button" class="btn bg-pri text-white ml-3">Lưu phiếu</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TiptapEditor from '@/components/Editors/Tiptap'
import appUtils from '@/utils/appUtils'
import {MENU_EMR_TEMPLATE} from '@/utils/constants'
import _ from 'lodash'
import {mapState} from 'vuex'

export default {
  name: 'ModalCreatePYLT',
  components: {TiptapEditor},
  props: ['type'],
  data () {
    const initialForm = {
      name: `Phiếu Y lệnh truyền ${appUtils.formatDateTimeFull(Date.now())}`,
      diagnostic: '',
      date: appUtils.formatInputDateTime(Date.now()),
      medical_record_id: 0,
      person_id: 0,
      weight: 0,
      height: 0,
      bsa: 0,
      gfr: 0,
      creatinine: 0,
      bp_systolic: 0,
      bp_diastolic: 0,
      temperature: 0,
      pulse: 0,
      content: appUtils.tableMedicalOrder(),
      pdf_url: null,
      delivery_time: appUtils.formatInputDateTime(Date.now()),
      org_id: Number(this.$globalClinic?.org_id) || 0,
      workspace_id: this.$clinicId || 0,
      treatment_doctor_id: null,
      chemical_id: null,
      infusion_id: null
    }
    return {
      medicalOrderId: null,
      loading: false,
      file: null,
      initialForm,
      formCreate: initialForm,
      aucForm: 0,
      aucRaw: 0,
      bsaForm: 0,
      bsaRaw: 0,
      weightForm: 0,
      weightRaw: 0,
      appUtils,
      doctors: [],
      vitalSign: null,
      params_vital_template: {
        emr_id: +this.$route.params?.id,
        limit: 50,
        page: 1,
        sort: 'date',
        sort_by: 'desc',
        is_template: 1
      },
      list_template: [],
      selected_template: null,
      docsTemplateList: [],
      doc_template_id: null
    }
  },
  async created () {
    await this.getDoctors()
  },
  async mounted () {
    let self = this
    await self.getDocsTemplateList()
    this.$nextTick(function () {
      window.$('#ModalPYLT').on('hidden.bs.modal', function (e) {
        if (self.type === 'create') {
          self.refresh()
        }
      })
    })

    this.handleSettingForm()
  },
  computed: {
    ...mapState({
      person: state => state.person,
      medicalRecordMain: state => state.medicalRecordMain,
      emrData: state => state.emrData
    }),
    bsa () {
      const result = this.formulaCalculateBSA(this.formCreate?.height, this.formCreate?.weight)
      const value = Number(result.toFixed(2))
      const valueArr = value.toString().split('.')
      this.formCreate.bsa = value || 0
      return result ? `${valueArr[1] === '00' ? valueArr[0] : value} m²` : 0
    },
    gfr () {
      const dateString = this.person?.birthday
      const date = new Date(dateString)
      const year = date.getFullYear()
      const age = this.person?.age || new Date().getFullYear() - year
      const genderValue = this.person?.gender === 1 ? 1 : 0.85
      const result = this.formCreate.creatinine ? this.formulaCalculateGFR(age, this.formCreate?.weight, genderValue, this.formCreate.creatinine) : 0
      const value = Number(result.toFixed(2))
      const valueArr = value.toString().split('.')
      this.formCreate.gfr = value || 0
      return result ? `${valueArr[1] === '00' ? valueArr[0] : value} mL/min` : 0
    },
    aucValue () {
      const { gfr } = this.formCreate
      const value = (this.aucForm * (gfr + 25)).toFixed(2)
      this.aucRaw = value || 0
      const valueArr = value.toString().split('.')
      return value ? `${valueArr[1] === '00' ? valueArr[0] : value} mg` : 0
    },
    bsaValue () {
      const { bsa } = this.formCreate
      const value = (this.bsaForm * bsa).toFixed(2)
      this.bsaRaw = value || 0
      const valueArr = value.toString().split('.')
      return value ? `${valueArr[1] === '00' ? valueArr[0] : value} mg/m²` : 0
    },
    weightValue () {
      const { weight } = this.formCreate
      const value = (this.weightForm * weight).toFixed(2)
      this.weightRaw = value || 0
      const valueArr = value.toString().split('.')
      return value ? `${valueArr[1] === '00' ? valueArr[0] : value} mg/kg` : 0
    }
  },
  watch: {
    emrData: {
      handler (data) {
        this.formCreate = {
          ...this.formCreate,
          medical_record_id: Number(data?.id) || Number(this.$route.params?.id) || 0,
          treatment_doctor_id: Number(data?.treat_doctor?.user_id) || null
        }
      },
      deep: true,
      immediate: true
    },
    medicalRecordMain: {
      handler (data) {
        this.formCreate = {
          ...this.formCreate,
          diagnostic: data?.treat_dept_main_name || '',
          weight: Number(data?.exam_weight) || 0,
          gfr: data?.exam_gfr || 0,
          bp_systolic: Number(data?.exam_blood_pressure?.split('/')[0]) || 0,
          bp_diastolic: Number(data?.exam_blood_pressure?.split('/')[1]) || 0,
          temperature: Number(data?.exam_temperature) || 0,
          pulse: Number(data?.exam_pulse) || 0
        }
      },
      deep: true,
      immediate: true
    },
    person: {
      handler (data) {
        this.formCreate = {
          ...this.formCreate,
          person_id: Number(data?.id) || 0,
          height: Number(data?.height) || 0
        }
      },
      deep: true,
      immediate: true
    },
    vitalSign: {
      handler (data) {
        this.formCreate = {
          ...this.formCreate,
          pulse: data?.bpm || 0,
          bp_systolic: data?.systolic_pressure || 0,
          bp_diastolic: data?.diastolic_pressure || 0,
          temperature: data?.temperature || 0,
          weight: data?.weight || 0
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    formulaCalculateGFR (age, weight, gender, creatinine) {
      return ((140 - age) * weight * gender) / (creatinine * 0.814)
    },
    formulaCalculateBSA (height, weight) {
      return 0.007184 * Math.pow(height, 0.725) * Math.pow(weight, 0.425)
    },
    handleSettingForm () {
      this.selected_template = null
      this.doc_template_id = null
      this.formCreate = {
        ...this.initialForm,
        name: `Phiếu Y lệnh truyền ${appUtils.formatDateTimeFull(Date.now())}`,
        medical_record_id: Number(this.emrData?.id) || Number(this.$route.params?.id) || 0,
        person_id: Number(this.person?.id) || 0,
        diagnostic: this.medicalRecordMain?.treat_dept_main_name || '',
        weight: Number(this.medicalRecordMain?.exam_weight) || Number(this.vitalSign?.weight) || 0,
        gfr: this.medicalRecordMain?.exam_gfr || 0,
        bp_systolic: Number(this.medicalRecordMain?.exam_blood_pressure?.split('/')[0]) || Number(this.vitalSign?.systolic_pressure) || 0,
        bp_diastolic: Number(this.medicalRecordMain?.exam_blood_pressure?.split('/')[1]) || Number(this.vitalSign?.diastolic_pressure) || 0,
        temperature: Number(this.medicalRecordMain?.exam_temperature) || Number(this.vitalSign?.temperature) || 0,
        pulse: Number(this.medicalRecordMain?.exam_pulse) || Number(this.vitalSign?.bpm) || 0,
        height: Number(this.person?.height) || 0,
        treatment_doctor_id: Number(this.emrData?.treat_doctor?.user_id) || null
      }
    },
    async getLastVitalsign () {
      if (!this.formCreate.medical_record_id) {
        this.$message({
          type: 'warning',
          message: 'Không lấy được thông tin của Bệnh án điện tử'
        })
        return
      }

      try {
        await this.$rf
          .getRequest('DoctorRequest')
          .getLastVitalsign(this.formCreate.medical_record_id)
          .then(res => {
            this.vitalSign = {...res.data?.data}
          })
      } catch (error) {
        console.log(error)
      }
    },
    async onSubmit (status) {
      if (!this.formCreate.medical_record_id) {
        this.$message({
          type: 'warning',
          message: 'Không lấy được thông tin của Bệnh án điện tử'
        })
        return
      }

      if (this.type === 'create') {
        this.loading = true
        let params
        if (status === 'template') {
          params = {
            ...this.formCreate,
            bp_diastolic: Number(this.formCreate.bp_diastolic) || 0,
            bp_systolic: Number(this.formCreate.bp_systolic) || 0,
            bsa: Number(this.formCreate.bsa) || 0,
            gfr: Number(this.formCreate.gfr) || 0,
            creatinine: Number(this.formCreate.creatinine) || 0,
            height: Number(this.formCreate.height) || 0,
            pulse: Number(this.formCreate.pulse) || 0,
            temperature: Number(this.formCreate.temperature) || 0,
            weight: Number(this.formCreate.weight) || 0,
            date: appUtils.formatDateTimeUTCTz(this.formCreate.date),
            delivery_time: appUtils.formatDateTimeUTCTz(this.formCreate.delivery_time),
            is_template: 1
          }
        } else {
          params = {
            ...this.formCreate,
            bp_diastolic: Number(this.formCreate.bp_diastolic) || 0,
            bp_systolic: Number(this.formCreate.bp_systolic) || 0,
            bsa: Number(this.formCreate.bsa) || 0,
            gfr: Number(this.formCreate.gfr) || 0,
            creatinine: Number(this.formCreate.creatinine) || 0,
            height: Number(this.formCreate.height) || 0,
            pulse: Number(this.formCreate.pulse) || 0,
            temperature: Number(this.formCreate.temperature) || 0,
            weight: Number(this.formCreate.weight) || 0,
            date: appUtils.formatDateTimeUTCTz(this.formCreate.date),
            delivery_time: appUtils.formatDateTimeUTCTz(this.formCreate.delivery_time)
          }
          delete params.is_template
        }
        delete params.created_at
        delete params.updated_at
        delete params.deleted_at
        delete params.id
        console.log(params)
        await this.$rf
          .getRequest('DoctorRequest')
          .createMedicalOrder(params)
          .then(r => {
            if (status !== 'template') {
              this.$message({
                message: 'Thêm phiếu thành công',
                type: 'success'
              })
            }

            if (status === 'template') {
              this.refresh()
              this.getListMedicalOrder()
              return
            }
            this.medicalOrderId = r.data?.data?.id
            window.$('#ModalPYLT').modal('hide')
            this.$emit('handleShowDetail', r?.data?.data)
            this.$emit('refresh')
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    refresh () {
      this.handleSettingForm()
    },
    closeModal () {
      if (this.type === 'create') {
        window.$('#ModalPYLT').modal('hide')
        this.vitalSign = null
      } else {
        //
      }
    },
    async deleteTemplate (item) {
      console.log('delete template')
      this.$confirm(`Bạn chắc chắn muốn xoá mẫu phiếu <strong>${item.name}</strong> không?`, 'Xác nhận xoá mẫu phiếu', {
        confirmButtonText: 'Xác nhận',
        dangerouslyUseHTMLString: true
      }).then(async _ => {
        this.loading = true
        await this.$rf
          .getRequest('DoctorRequest')
          .deleteTemplateMedicalOrder(item.id)
          .then(async r => {
            console.log(r)
            if (r?.status === 200) {
              await this.getListMedicalOrder()
              this.$message({
                message: 'Xoá template thành công',
                type: 'success'
              })
            } else {
            }
          })
          .finally(() => {
            this.loading = false
          })
      })
    },
    async openModal (show) {
      if (show) {
        await this.getListMedicalOrder()
        await this.handleUseVitalsign()
        window.$('#ModalPYLT').modal(show ? 'show' : 'hide')
      }
    },
    changeSelectTemplate (e) {
      let list = this.list_template.find(x => x?.id === e)
      let y = _.cloneDeep(list)

      this.doc_template_id = null
      this.formCreate = {
        ...this.formCreate,
        creatinine: Number(y?.creatinine) || 0,
        weight: Number(y?.weight) || Number(this.vitalSign?.weight) || 0,
        bp_systolic: Number(y?.bp_systolic) || Number(this.vitalSign?.systolic_pressure) || 0,
        bp_diastolic: Number(y?.bp_diastolic) || Number(this.vitalSign?.diastolic_pressure) || 0,
        temperature: Number(y?.temperature) || Number(this.vitalSign?.temperature) || 0,
        pulse: Number(y?.pulse) || Number(this.vitalSign?.bpm) || 0,
        height: Number(y?.height) || Number(this.formCreate.height) || 0,
        content: y?.content || ''
      }
    },
    async onSubmitTemplate () {
      this.$confirm('Bạn có chắc chắn muốn lưu mẫu phiếu này không?', 'Xác nhận lưu mẫu phiếu', {
        confirmButtonText: 'Xác nhận'
      })
        .then(async _ => {
          await this.onSubmit('template')
          this.$message({
            message: 'Lưu mẫu thành công',
            type: 'success'
          })
          window.$('#ModalPYLT').modal('hide')
          this.$emit('refresh')
        })
        .catch(_ => {
        })
    },
    async getListMedicalOrder () {
      this.loading = true
      await this.$rf
        .getRequest('DoctorRequest')
        .getListMedicalOrder(this.params_vital_template)
        .then(r => {
          console.log(r)
          if (r?.status === 200) {
            this.list_template = r?.data?.data?.data || []
          } else {
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    async getDoctors () {
      const clinic_id = this.$clinicId
      await this.$rf
        .getRequest('DoctorRequest')
        .getDoctorsOfWorkspace(clinic_id, {limit: 1000})
        .then(res => {
          this.doctors = res.data?.data
        })
    },
    async getDocsTemplateList (search) {
      try {
        this.loading = true
        const params = {
          workspace_id: this.$globalClinic.id,
          limit: 1000,
          sort_by: 'desc',
          type: Number(MENU_EMR_TEMPLATE[6].value)
        }
        if (search) {
          params.keyword = search
        }
        await this.$rf
          .getRequest('DoctorRequest')
          .getDocsTemplateList(params)
          .then(r => {
            const responseData = r.data?.data?.data || []
            this.docsTemplateList = responseData.filter(item => item?.author_id === this.$user?.id || item?.emr_temp_name?.is_public === 1)
          })
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    handleGetTemLabel (data) {
      if (data?.emr_temp_name?.name) {
        return `${data.name} - ${data.emr_temp_name.name}`
      }

      return data.name
    },
    handleChangeDocTemplate (selectedDoc) {
      const docTemplateInfo = this.docsTemplateList.find(item => item.id === selectedDoc) || {}
      const contentObj = docTemplateInfo.content ? JSON.parse(docTemplateInfo.content) : {}

      this.selected_template = null
      this.formCreate = {
        ...this.formCreate,
        creatinine: Number(contentObj?.creatinine?.value) || 0,
        weight: Number(contentObj?.weight?.value) || Number(this.vitalSign?.weight) || 0,
        bp_systolic: Number(contentObj?.bp_systolic?.value) || Number(this.vitalSign?.systolic_pressure) || 0,
        bp_diastolic: Number(contentObj?.bp_diastolic?.value) || this.vitalSign?.diastolic_pressure || 0,
        temperature: Number(contentObj?.temperature?.value) || Number(this.vitalSign?.temperature) || 0,
        pulse: Number(contentObj?.pulse?.value) || Number(this.vitalSign?.bpm) || 0,
        height: Number(contentObj?.height?.value) || 0,
        content: contentObj?.content?.value || ''
      }
    },
    async handleUseVitalsign () {
      await this.getLastVitalsign()
      this.formCreate = {
        ...this.formCreate,
        pulse: this.vitalSign?.bpm || 0,
        bp_systolic: this.vitalSign?.systolic_pressure || 0,
        bp_diastolic: this.vitalSign?.diastolic_pressure || 0,
        temperature: this.vitalSign?.temperature || 0,
        weight: this.vitalSign?.weight || 0
      }
    },
    copyValue (value) {
      let el_value = document.createElement('input')
      document.body.appendChild(el_value)
      el_value.value = value
      el_value.select()
      el_value.setSelectionRange(0, 99999)
      navigator.clipboard.writeText(el_value.value)
      document.execCommand('copy')
      this.$toast.open({
        message: this.$t('Đã sao chép'),
        type: 'success'
      })
      el_value.remove()
    }
  }
}
</script>
