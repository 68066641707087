<template>
  <div class="text-black mb-3">
    <div v-if="!showDetail">
      <div class="mb-0 text-blue-800 text-base flex justify-end">
        <div
          class="text-black fs-14 mr-3 cursor-pointer fw-600"
          @click="onShowModalUpload(true)"
        >
          <svg
            class="mr-1"
            width="16"
            height="16"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.039 5.13579L8.1 3.06528V11.6984C8.1 11.9372 8.19482 12.1662 8.3636 12.335C8.53239 12.5038 8.76131 12.5987 9 12.5987C9.23869 12.5987 9.46761 12.5038 9.6364 12.335C9.80518 12.1662 9.9 11.9372 9.9 11.6984V3.06528L11.961 5.13579C12.0447 5.22017 12.1442 5.28714 12.2539 5.33284C12.3636 5.37855 12.4812 5.40208 12.6 5.40208C12.7188 5.40208 12.8364 5.37855 12.9461 5.33284C13.0558 5.28714 13.1553 5.22017 13.239 5.13579C13.3234 5.05211 13.3903 4.95254 13.436 4.84284C13.4817 4.73314 13.5052 4.61547 13.5052 4.49663C13.5052 4.37779 13.4817 4.26013 13.436 4.15043C13.3903 4.04073 13.3234 3.94116 13.239 3.85747L9.639 0.256576C9.55341 0.174619 9.45248 0.110375 9.342 0.067529C9.12288 -0.0225097 8.87712 -0.0225097 8.658 0.067529C8.54752 0.110375 8.44659 0.174619 8.361 0.256576L4.761 3.85747C4.67709 3.94141 4.61052 4.04106 4.56511 4.15072C4.51969 4.26039 4.49632 4.37793 4.49632 4.49663C4.49632 4.61534 4.51969 4.73288 4.56511 4.84254C4.61052 4.95221 4.67709 5.05186 4.761 5.13579C4.84491 5.21973 4.94454 5.28631 5.05418 5.33173C5.16382 5.37716 5.28133 5.40054 5.4 5.40054C5.51867 5.40054 5.63618 5.37716 5.74582 5.33173C5.85546 5.28631 5.95509 5.21973 6.039 5.13579ZM17.1 8.99776C16.8613 8.99776 16.6324 9.0926 16.4636 9.26142C16.2948 9.43025 16.2 9.65922 16.2 9.89798V15.2993C16.2 15.5381 16.1052 15.7671 15.9364 15.9359C15.7676 16.1047 15.5387 16.1996 15.3 16.1996H2.7C2.4613 16.1996 2.23239 16.1047 2.0636 15.9359C1.89482 15.7671 1.8 15.5381 1.8 15.2993V9.89798C1.8 9.65922 1.70518 9.43025 1.5364 9.26142C1.36761 9.0926 1.13869 8.99776 0.9 8.99776C0.661305 8.99776 0.432387 9.0926 0.263604 9.26142C0.0948211 9.43025 0 9.65922 0 9.89798V15.2993C0 16.0156 0.284464 16.7025 0.790812 17.209C1.29716 17.7155 1.98392 18 2.7 18H15.3C16.0161 18 16.7028 17.7155 17.2092 17.209C17.7155 16.7025 18 16.0156 18 15.2993V9.89798C18 9.65922 17.9052 9.43025 17.7364 9.26142C17.5676 9.0926 17.3387 8.99776 17.1 8.99776Z"
              fill="#03267A"
            />
          </svg>
          Upload Phiếu
        </div>
        <div class="flex items-center">
          <span
            @click="ModalCreate"
            class="fs-14 fw-700 text-grey-900 cursor-pointer"
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="mr-2"
            >
              <path
                d="M8.99984 0.666748C7.35166 0.666748 5.7405 1.15549 4.37009 2.07117C2.99968 2.98685 1.93158 4.28834 1.30084 5.81105C0.670115 7.33377 0.505087 9.00933 0.82663 10.6258C1.14817 12.2423 1.94185 13.7272 3.10728 14.8926C4.27272 16.0581 5.75758 16.8517 7.37409 17.1733C8.9906 17.4948 10.6662 17.3298 12.1889 16.6991C13.7116 16.0683 15.0131 15.0002 15.9288 13.6298C16.8444 12.2594 17.3332 10.6483 17.3332 9.00008C17.3332 7.90573 17.1176 6.8221 16.6988 5.81105C16.28 4.80001 15.6662 3.88135 14.8924 3.10752C14.1186 2.3337 13.1999 1.71987 12.1889 1.30109C11.1778 0.882296 10.0942 0.666748 8.99984 0.666748ZM8.99984 15.6667C7.6813 15.6667 6.39237 15.2758 5.29604 14.5432C4.19971 13.8107 3.34523 12.7695 2.84064 11.5513C2.33606 10.3331 2.20404 8.99269 2.46127 7.69948C2.71851 6.40627 3.35345 5.21839 4.2858 4.28604C5.21815 3.35369 6.40603 2.71875 7.69924 2.46151C8.99245 2.20428 10.3329 2.3363 11.5511 2.84088C12.7692 3.34547 13.8104 4.19995 14.543 5.29628C15.2755 6.39261 15.6665 7.68154 15.6665 9.00008C15.6665 10.7682 14.9641 12.4639 13.7139 13.7141C12.4636 14.9644 10.768 15.6667 8.99984 15.6667ZM12.3332 8.16675H9.83317V5.66675C9.83317 5.44573 9.74538 5.23377 9.5891 5.07749C9.43282 4.92121 9.22085 4.83342 8.99984 4.83342C8.77883 4.83342 8.56687 4.92121 8.41059 5.07749C8.25431 5.23377 8.16651 5.44573 8.16651 5.66675V8.16675H5.66651C5.44549 8.16675 5.23353 8.25455 5.07725 8.41083C4.92097 8.56711 4.83317 8.77907 4.83317 9.00008C4.83317 9.2211 4.92097 9.43306 5.07725 9.58934C5.23353 9.74562 5.44549 9.83342 5.66651 9.83342H8.16651V12.3334C8.16651 12.5544 8.25431 12.7664 8.41059 12.9227C8.56687 13.079 8.77883 13.1667 8.99984 13.1667C9.22085 13.1667 9.43282 13.079 9.5891 12.9227C9.74538 12.7664 9.83317 12.5544 9.83317 12.3334V9.83342H12.3332C12.5542 9.83342 12.7662 9.74562 12.9224 9.58934C13.0787 9.43306 13.1665 9.2211 13.1665 9.00008C13.1665 8.77907 13.0787 8.56711 12.9224 8.41083C12.7662 8.25455 12.5542 8.16675 12.3332 8.16675Z"
                fill="#03267A"
              />
            </svg>
            Thêm Phiếu mới
          </span>
        </div>
      </div>

      <div v-if="treatSums">
        <el-table
          :data="treatSums"
          style="width: 100%"
          row-class-name="text-black text-sm"
          header-cell-class-name="text-black text-base font-semibold"
          @row-click="goToViewDetail"
          class="cursor-pointer"
        >
          <el-table-column
            prop="name"
            label="Tên phiếu"
            :formatter="formatName"
          >
          </el-table-column>
          <el-table-column prop="date" label="Ngày điều trị" sortable>
            <template slot-scope="scope">
              <span class="">{{ formatDMY(scope.row.date) }}</span>
            </template>
            <!-- {{ scope.row.date }} -->
          </el-table-column>
          <el-table-column prop="created_at" label="Ngày tạo" sortable>
            <template slot-scope="scope">
              <span class="">{{ formatDMY(scope.row.created_at) }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="date"
            label="Người tạo"
            sortable
            :formatter="formatDMY"
          >
          </el-table-column> -->
          <!-- <el-table-column
            prop="status"
            label="Trạng thái"
            sortable
            :formatter="formatStatus"
          >
          </el-table-column> -->
          <!-- <el-table-column
            prop="format"
            label="Định dạng"
            class-name="text-center"
            :formatter="formatFile"
          >
          </el-table-column> -->
          <!-- <el-table-column
            label="Nguồn dữ liệu"
            :formatter="getUserName"
          >
          </el-table-column> -->
        </el-table>
        <div class="flex justify-content-end mt-3">
          <el-pagination
            v-if="treatSums"
            background
            layout="prev, pager, next"
            :total="treatSumspaging.total"
            :page-size="parseInt(treatSumspaging.per_page)"
            @current-change="handleCurrentPage"
          >
          </el-pagination>
        </div>
      </div>
      <ModalEMRUpload @onSubmit="postERMTreatSum($event)" />
    </div>
    <div v-else>
      <Detail />
    </div>
    <modalCreateForm :type="'create'" @onCreate="createERMTreatSum($event)" />
    <div
      class="modal fade"
      id="ModalChiTietPSK"
      tabindex="-1"
      role="dialog"
      aria-labelledby="ModalChiTietPSKLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="d-flex justify-content-between p-3">
            <div class="fs-24 fw-700 txt-grey-900 text-center w-100 uppercase">
              {{ update.show ? "Chỉnh sửa phiếu" : "Chi tiết phiếu" }}
            </div>
            <div class="flex flex-end items-center">
              <svg
                width="18"
                height="18"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="mr-2 cursor-pointer"
                @click="onUpdate"
                v-if="
                  viewingFileDetail &&
                  viewingFileDetail.user_id &&
                  viewingFileDetail.user_id == $user.id
                "
              >
                <path
                  d="M21.8749 12.4999C21.5987 12.4999 21.3337 12.6096 21.1383 12.805C20.943 13.0003 20.8333 13.2653 20.8333 13.5415V19.7915C20.8333 20.0678 20.7235 20.3327 20.5282 20.5281C20.3328 20.7234 20.0679 20.8332 19.7916 20.8332H5.20825C4.93198 20.8332 4.66703 20.7234 4.47168 20.5281C4.27633 20.3327 4.16659 20.0678 4.16659 19.7915V5.20819C4.16659 4.93192 4.27633 4.66697 4.47168 4.47162C4.66703 4.27627 4.93198 4.16652 5.20825 4.16652H11.4583C11.7345 4.16652 11.9995 4.05678 12.1948 3.86142C12.3902 3.66607 12.4999 3.40112 12.4999 3.12486C12.4999 2.84859 12.3902 2.58364 12.1948 2.38829C11.9995 2.19294 11.7345 2.08319 11.4583 2.08319H5.20825C4.37945 2.08319 3.58459 2.41243 2.99854 2.99848C2.41249 3.58453 2.08325 4.37939 2.08325 5.20819V19.7915C2.08325 20.6203 2.41249 21.4152 2.99854 22.0012C3.58459 22.5873 4.37945 22.9165 5.20825 22.9165H19.7916C20.6204 22.9165 21.4152 22.5873 22.0013 22.0012C22.5873 21.4152 22.9166 20.6203 22.9166 19.7915V13.5415C22.9166 13.2653 22.8068 13.0003 22.6115 12.805C22.4161 12.6096 22.1512 12.4999 21.8749 12.4999ZM6.24992 13.2915V17.7082C6.24992 17.9845 6.35967 18.2494 6.55502 18.4448C6.75037 18.6401 7.01532 18.7499 7.29159 18.7499H11.7083C11.8453 18.7506 11.9812 18.7244 12.1082 18.6725C12.2351 18.6207 12.3505 18.5443 12.4478 18.4478L19.6562 11.229L22.6145 8.33319C22.7121 8.23635 22.7896 8.12114 22.8425 7.99421C22.8954 7.86727 22.9226 7.73112 22.9226 7.59361C22.9226 7.45609 22.8954 7.31994 22.8425 7.193C22.7896 7.06607 22.7121 6.95086 22.6145 6.85402L18.1978 2.38527C18.101 2.28764 17.9858 2.21014 17.8589 2.15726C17.7319 2.10438 17.5958 2.07715 17.4583 2.07715C17.3207 2.07715 17.1846 2.10438 17.0577 2.15726C16.9307 2.21014 16.8155 2.28764 16.7187 2.38527L13.7812 5.33319L6.552 12.5519C6.45546 12.6493 6.37908 12.7647 6.32724 12.8916C6.2754 13.0185 6.24913 13.1544 6.24992 13.2915ZM17.4583 4.59361L20.4062 7.54152L18.927 9.02069L15.9791 6.07277L17.4583 4.59361ZM8.33325 13.7186L14.5103 7.54152L17.4583 10.4894L11.2812 16.6665H8.33325V13.7186Z"
                  fill="black"
                />
              </svg>

              <button
                :disabled="loading"
                type="button"
                class="close bg-white border-0"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" class="fs-30 fw-500">&times;</span>
              </button>
            </div>
          </div>
          <div class="modal-body pt-0">
            <div class="row">
              <div class="col-4 mb-2">
                <p class="fs-14 fw-500 txt-grey-900">Tên phiếu</p>
              </div>
              <div class="col-8 mb-2">
                <p class="fs-14 txt-grey-900" v-if="!update.show">
                  {{ viewingFileDetail && viewingFileDetail.name }}
                </p>
                <input
                  v-else
                  type="text"
                  v-model="update.form.name"
                  class="form-control"
                />
              </div>
              <div class="col-4 mb-2">
                <p class="fs-14 fw-500 txt-grey-900">Ngày giờ tạo phiếu</p>
              </div>
              <div class="col-8 mb-2">
                <p class="fs-14 txt-grey-900" v-if="!update.show">
                  {{
                    viewingFileDetail &&
                    viewingFileDetail.date &&
                    moment(viewingFileDetail.date).format(
                      "HH:mm:ss DD/MM/YYYY "
                    )
                  }}
                </p>
                <input
                  v-else
                  type="datetime-local"
                  v-model="update.form.date"
                  class="form-control"
                />
              </div>
              <div class="col-4">
                <p class="fs-14 fw-500 txt-grey-900" v-if="!update.show">
                  Người tạo phiếu
                </p>
              </div>
              <div class="col-8">
                <p class="fs-14 txt-grey-900" v-if="!update.show">
                  {{
                    viewingFileDetail &&
                    viewingFileDetail.user &&
                    viewingFileDetail.user.name
                  }}
                </p>
              </div>
              <div class="col-12 text-center" v-if="!update.show">
                <a
                  href="javascript:;"
                  class="txt-pri fs-14 text-decoration-underline"
                  @click="
                    openFile(viewingFileDetail && viewingFileDetail.pdf_url)
                  "
                  >Xem phiếu</a
                >
              </div>
              <div class="col-12 flex mt-2" v-else>
                <div class="w-75">
                  <p class="fs-14 fw-500 txt-grey-900" v-if="!update.form.pdf">
                    {{ appUtils.getFilename(viewingFileDetail.pdf_url) }}
                  </p>
                  <p class="fs-14 fw-500 txt-grey-900" v-else>
                    {{ appUtils.getFilename(update.form.pdf.name) }}
                  </p>
                </div>
                <div class="w-25 text-right">
                  <a
                    href="javascript:;"
                    class="txt-grey-900 fs-14 text-decoration-underline"
                    @click="openSelectFile"
                    >Thay đổi</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer" v-if="update.show">
            <button type="button" class="btn btn-muted" @click="onCancelUpdate">
              Huỷ
            </button>
            <button
              :disabled="loading"
              type="button"
              class="btn bg-pri bd-pri text-white"
              @click="onSubmitUpdate"
            >
              <span
                class="spinner-border spinner-border-sm mr-1 fs-12"
                role="status"
                v-if="loading"
              ></span>
              <svg
                v-else
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="mr-1"
              >
                <path
                  d="M5.34961 3.61963H16.5696C17.067 3.61968 17.5465 3.80508 17.9146 4.13963L20.6946 6.66663C20.9009 6.85406 21.0657 7.08254 21.1785 7.33741C21.2913 7.59228 21.3496 7.86791 21.3496 8.14663V19.6196C21.3496 20.1501 21.1389 20.6588 20.7638 21.0338C20.3888 21.4089 19.88 21.6196 19.3496 21.6196H5.34961C4.81918 21.6196 4.31047 21.4089 3.9354 21.0338C3.56032 20.6588 3.34961 20.1501 3.34961 19.6196V5.61963C3.34961 5.0892 3.56032 4.58049 3.9354 4.20542C4.31047 3.83034 4.81918 3.61963 5.34961 3.61963V3.61963ZM5.34961 5.61963V19.6196H19.3496V8.14663L16.5696 5.61963H5.34961ZM9.34961 13.6196H15.3496C15.88 13.6196 16.3888 13.8303 16.7638 14.2054C17.1389 14.5805 17.3496 15.0892 17.3496 15.6196V19.6196C17.3496 20.1501 17.1389 20.6588 16.7638 21.0338C16.3888 21.4089 15.88 21.6196 15.3496 21.6196H9.34961C8.81918 21.6196 8.31047 21.4089 7.9354 21.0338C7.56032 20.6588 7.34961 20.1501 7.34961 19.6196V15.6196C7.34961 15.0892 7.56032 14.5805 7.9354 14.2054C8.31047 13.8303 8.81918 13.6196 9.34961 13.6196ZM9.34961 15.6196V19.6196H15.3496V15.6196H9.34961ZM16.3496 6.61963C16.6148 6.61963 16.8692 6.72499 17.0567 6.91252C17.2443 7.10006 17.3496 7.35441 17.3496 7.61963V10.6196C17.3496 10.8848 17.2443 11.1392 17.0567 11.3267C16.8692 11.5143 16.6148 11.6196 16.3496 11.6196C16.0844 11.6196 15.83 11.5143 15.6425 11.3267C15.455 11.1392 15.3496 10.8848 15.3496 10.6196V7.61963C15.3496 7.35441 15.455 7.10006 15.6425 6.91252C15.83 6.72499 16.0844 6.61963 16.3496 6.61963ZM8.34961 6.61963H13.3496C13.6148 6.61963 13.8692 6.72499 14.0567 6.91252C14.2443 7.10006 14.3496 7.35441 14.3496 7.61963V10.6196C14.3496 10.8848 14.2443 11.1392 14.0567 11.3267C13.8692 11.5143 13.6148 11.6196 13.3496 11.6196H8.34961C8.08439 11.6196 7.83004 11.5143 7.6425 11.3267C7.45497 11.1392 7.34961 10.8848 7.34961 10.6196V7.61963C7.34961 7.35441 7.45497 7.10006 7.6425 6.91252C7.83004 6.72499 8.08439 6.61963 8.34961 6.61963ZM9.34961 9.61963H12.3496V8.61963H9.34961V9.61963Z"
                  fill="white"
                />
              </svg>
              Lưu
            </button>
          </div>
        </div>
      </div>
    </div>
    <input
      ref="selectFileUpdatePSK"
      class="d-none"
      name="file-upload"
      type="file"
      @change="onSelectFile"
      accept="jpeg, .png, .jpg, .gif, .svg, .doc, .docx, .pdf, .xls, .xlsx, .xlsm, .ppt, .pptx, .heic"
    />
    <ModalSignPDF
      ref="ListSoKetModalSignPDF"
      containerIDProps="ListSoKetContainerSignPDFID"
      elementIDProps="ListSoKetSignPDFID"
      imageIDProps="ListSoKetSignPDFImageID"
      :envelopeName="envelopeName"
      @onSuccess="handleSignDocumentSuccess"
    />
    <PdfViewer
      ref="ListSoKetPdfViewer"
      containerIDProps="ContainerListSoKetPDFViewerID"
      elementIDProps="ListSoKetPDFViewerID"
    />
  </div>
</template>
  
<script>
import modalCreateForm from './ModalPhieuSoKet.vue'
import ModalEMRUpload from '../ModalUpload.vue'
import Detail from './Detail'
import moment from 'moment'
import appUtils from '../../../utils/appUtils'
import PdfViewer from '../../PdfViewer/PdfViewer.vue'
import {
  ENVELOPE_DOCUMENT_TYPE,
  ENVELOPE_NAME_TYPE
} from '../../../utils/constants'
import SignatureRequest from '../../../api/request/SignatureRequest'
import uploadS3File from '../../../utils/uploadS3File'
import ModalSignPDF from '../../Signature/ModalSignPDF.vue'
import { mixinSignPdf } from '../../../utils/mixinSignPdf'
import { mapState } from 'vuex'

export default {
  name: 'PhieuSoKetList',
  mixins: [mixinSignPdf],
  components: {
    Detail,
    ModalEMRUpload,
    modalCreateForm,
    PdfViewer,
    ModalSignPDF
  },
  data () {
    return {
      treatSums: null,
      treatSumspaging: null,
      listStatus: [
        { name: 'Mới' },
        { name: 'Đang xử lý' },
        { name: 'Đã ký' },
        { name: 'Đã thay thế' }
      ],
      showDetail: false,
      viewingFileDetail: null,
      moment,
      update: {
        form: {
          name: '',
          date: moment().format('YYYY-MM-DD HH:mm'),
          pdf_url: '',
          pdf: null
        },
        show: false
      },
      loading: false,
      appUtils,
      selectedFile: null,
      modalUpdateSaved: false
    }
  },
  watch: {
    '$route.query.vId' () {
      this.handleDetail()
    },
    showDetail (val) {
      if (!val) this.getERMTreatSums()
    }
  },
  computed: {
    ...mapState({
      emrData: (state) => state.emrData || {}
    }),
    envelopeName () {
      return this.handleGetEnvelopeName(ENVELOPE_NAME_TYPE.emr, this.emrData)
    }
  },
  mounted () {
    this.handleDetail()
    if (!this.showDetail) this.getERMTreatSums()
    let self = this
    this.$nextTick(function () {
      window.$('#ModalChiTietPSK').on('hidden.bs.modal', function (e) {
        let query = Object.assign({}, self.$route.query)
        delete query.vId
        delete query.type
        self.$router.replace({ query }).catch((_) => {})
        self.getERMTreatSums()
        self.viewingFileDetail = null
        self.onCancelUpdate()
      })

      window.$('#ModalChiTietPSK').on('hide.bs.modal', function (e) {
        if (self.update.show && !self.modalUpdateSaved) {
          if (
            !confirm(
              'Các thay đổi có thể chưa được lưu. Bạn có muốn lưu không?'
            )
          ) {
            return true
          } else self.onSubmitUpdate()
        } else {
          self.modalUpdateSaved = false
        }
      })
    })
  },
  methods: {
    ModalCreate (show) {
      window.$('#modalPhieuSoKet').modal(show ? 'show' : 'hide')
    },
    handleCurrentPage (page) {
      let query = Object.assign({}, this.$route.query)
      query.page = page
      this.$router.replace({ query }).catch((_) => {})
      this.getERMTreatSums()
    },
    async createERMTreatSum (event) {
      this.loading = true
      const params = {
        ...event,
        medical_record_id: this.$route.params.id
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .postERMTreatSum(params)
        .then((r) => {
          const dataId = r.data
          this.goToViewDetail(dataId)
          this.getERMTreatSums()
          this.$toast.open({
            message: 'Tải lên thành công',
            type: 'success'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    async handleDetail () {
      if (this.$route.query.vId) {
        let data = await this.getERMTreatSumByID(this.$route.query.vId)
        if (data?.pdf_url) {
          this.onViewFile(data)
        } else {
          this.showDetail = true
        }
      } else {
        this.showDetail = false
      }
    },
    formatStatus (row) {
      return row.status
        ? this.listStatus[row.status].name
        : this.listStatus[0].name
    },
    formatName (row) {
      return row.name
    },
    formatFile (row) {
      return row.format === 1 ? 'Dữ liệu' : 'File'
    },
    formatDMY (date) {
      if (date) {
        return window.moment(date).format('HH:mm:ss DD/MM/YYYY')
      } else {
        return ''
      }
    },
    getUserName (row) {
      return row.user && row.user.name
    },
    async goToViewDetail (row) {
      this.loading = true

      const envelopeInfo = await this.openModalSignPDF(row)

      if (envelopeInfo) return

      this.$router
        .push({
          query: { tab: this.$route.query.tab, vId: row.id, type: 'view' }
        })
        .catch((_) => {})
    },
    onShowModalUpload (show) {
      window.$('#modal__emr__upload').modal(show ? 'show' : 'hide')
    },
    onShowModaViewFile (show) {
      window.$('#ModalChiTietPSK').modal(show ? 'show' : 'hide')
    },
    async getERMTreatSums () {
      let id = this.$route.params.id
      if (!id) {
        return
      }
      this.loading = true
      const params = {
        limit: 50,
        page: this.$route.query.page || 1,
        sort: 'created_at',
        sort_by: 'desc'
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .getERMTreatSums(id, params)
        .then((r) => {
          this.treatSums = r.data?.data
          this.treatSumspaging = r.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    async postERMTreatSum (event) {
      const formData = new FormData()
      for (const key in event) {
        if (event[key] && key === 'date') {
          formData.append(key, event[key])
        } else if (event[key]) {
          formData.append(key, event[key])
        }
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .postERMTreatSum(formData)
        .then((r) => {
          if (r) this.getERMTreatSums()
          this.$toast.open({
            message: 'Upload file thành công',
            type: 'success'
          })
        })
    },
    async getERMTreatSumByID () {
      let id = this.$route.query.vId
      if (!id) {
        return
      }
      this.loading = true
      const r = await this.$rf
        .getRequest('DoctorRequest')
        .getERMTreatSumByID(id)
        .then((r) => {
          return r.data
        })
        .finally(() => {
          this.loading = false
        })
      return r
    },
    onViewFile (item) {
      if (!item) return
      this.viewingFileDetail = item
      this.onShowModaViewFile(true)
    },
    onUpdate () {
      let item = { ...this.viewingFileDetail }
      this.modalUpdateSaved = false
      this.update = {
        form: {
          id: item.id,
          name: item.name,
          date:
            (item.date && moment(item.date).format('YYYY-MM-DD HH:mm')) ||
            moment().format('YYYY-MM-DD HH:mm'),
          pdf_url: item.pdf_url,
          pdf: null
        },
        show: true
      }
    },
    openFile (url) {
      if (!url) return
      window.open(url)
    },
    onCancelUpdate () {
      this.update = {
        id: null,
        form: {
          name: '',
          date: moment().format('YYYY-MM-DD HH:mm'),
          pdf_url: '',
          pdf: null
        },
        show: false,
        pdf: null
      }
      this.selectedFile = null
    },
    async onSubmitUpdate () {
      if (!this.update.form.id) return
      if (!this.update.form.name) {
        alert('Vui lòng nhập tên')
        return
      }
      if (!this.update.form.date) {
        alert('Vui lòng nhập ngày')
        return
      }
      this.modalUpdateSaved = true
      const formData = new FormData()
      const data = (({ name, date, pdf, pdf_url }) => ({
        name,
        date,
        pdf,
        pdf_url
      }))(this.update.form)
      for (const key in data) {
        if (data[key]) formData.append(key, data[key])
      }
      this.loading = true
      await this.$rf
        .getRequest('DoctorRequest')
        .updateERMTreatSum(this.update.form.id, formData)
        .then((r) => {
          this.$toast.open({
            message: 'Cập nhật thành công',
            type: 'success'
          })
          this.viewingFileDetail = {
            user: this.viewingFileDetail?.user,
            ...r.data
          }
          this.onCancelUpdate()
          this.getERMTreatSums()
        })
        .finally(() => {
          this.loading = false
        })
    },
    openSelectFile () {
      this.$refs.selectFileUpdatePSK.click()
      this.$refs.selectFileUpdatePSK.value = null
    },
    onSelectFile (event) {
      this.selectedFile = event.target.files[0]
      this.update.form.pdf = event.target.files[0]
      this.$refs.selectFileUpdatePSK.value = null
    },

    async openModalSignPDF (item) {
      const envelopeInfo = await this.handleCheckSignEnvelope(item)

      return envelopeInfo
    },
    async handleProcessProcedureData (callback) {
      const procedure = await this.getSignProcedures()

      const signatories = procedure?.sign_nodes?.reduce((arr, node) => {
        const signs = node?.node_signatories?.map((item) => {
          const user = this.getUserByRole(item?.user_role, callback)

          return {
            ...item,
            user,
            user_id: user?.id
          }
        })

        return arr.concat(signs)
      }, [])

      return { ...procedure, signatories }
    },
    async handleCheckSignEnvelope (item) {
      try {
        const documentID = item?.id
        const signEnvelope = await this.getSignEnvelopeByDocID(item)
        if (!signEnvelope) return

        const signatories = signEnvelope?.envelope_signatories?.map((item) => ({
          ...item,
          user: item?.user_info,
          user_id: item?.user_id
        }))

        const signerInfo = signEnvelope?.envelope_signatories?.find(
          (item) => item?.user_id === this.$user?.id
        )

        const s3Url = await uploadS3File.getLinkVideoAWS(
          signEnvelope?.document_url
        )

        if (signerInfo?.status === 2) {
          this.$nextTick(() => {
            this.$refs.ListSoKetPdfViewer.handleOpen(
              s3Url,
              ENVELOPE_DOCUMENT_TYPE.SUMMARY_TREATMENT_FORM,
              documentID,
              false,
              false
            )
          })
        } else {
          const hasPermissionSignatory = signatories?.some(
            (item) => item?.user_id === this.$user?.id
          )

          if (!hasPermissionSignatory) {
            this.$toast.open({
              message: 'Bạn không có quyền ký tài liệu này',
              type: 'error'
            })
            return
          }
          this.$nextTick(() => {
            this.$refs.ListSoKetModalSignPDF.handleOpen(
              s3Url,
              ENVELOPE_DOCUMENT_TYPE.SUMMARY_TREATMENT_FORM,
              documentID,
              signerInfo?.user_id,
              signatories || [],
              signEnvelope
            )
          })
        }

        return signEnvelope
      } catch (error) {
        console.log(error)
      }
    },
    async handleSignDocumentSuccess (_, envelopeData) {},
    async getSignEnvelopeByDocID (item) {
      try {
        const request = new SignatureRequest()
        const response = await request.getSignEnvelopeByDocumentID({
          doc_id: item?.id,
          doc_type: ENVELOPE_DOCUMENT_TYPE.SUMMARY_TREATMENT_FORM
        })

        return response.data
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
